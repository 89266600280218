import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ingredientShape from '../../shapes/ingredientShape';

import Image from '../../containers/Image';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import Button from '../Button';
import DietTags from '../DietTags';
import Modal from '../Modal';

import './DietConflictResolver.css';

function DietConflictResolver(props) {
  const { ingredients, ingredientConflictingTags, onReset, onSave } = props;

  const { isMobile } = useDeviceDetect();

  return (
    <Modal className="DietConflictResolver" show={ingredients.length > 0} onHide={() => {}}>
      <Modal.Body className="kale">
        <Typography variant="h3" color="secondary.dark" className="hidden-xs DietConflictTitle">
          Dietary Preferences
        </Typography>
        <p className="DietConflictDesc">
          The following ingredients will be removed if allergens are applied.
        </p>
        <div className="hidden-xs">
          <DietTags />
        </div>
        <ul className="diet-item-list">
          {ingredients.map((ingredient) => (
            <li key={ingredient.id}>
              <ul className="diet-item">
                <li className="diet-item-image">
                  <Image id={ingredient.image} />
                </li>
                <li className="diet-item-details">
                  <Typography variant="subtitle2" fontWeight="600">
                    {ingredient.name}
                  </Typography>
                  <Typography variant="body2" className="dietConflictLabel">
                    {ingredientConflictingTags[ingredient.id].map((tag) => (
                      <span key={tag.id} className="addComma">
                        <FormattedMessage id={`tag.name.${tag.id}`} />
                      </span>
                    ))}
                  </Typography>
                </li>
              </ul>
            </li>
          ))}
        </ul>
        <ul className="button">
          <li>
            <Button onClick={onReset} className="btn-secondary">
              Cancel
            </Button>
          </li>
          <li>
            <Button onClick={onSave} className="btn-primary">
              {isMobile ? 'Remove' : 'Save'}
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}

DietConflictResolver.propTypes = {
  ingredients: PropTypes.arrayOf(ingredientShape).isRequired,
  ingredientConflictingTags: PropTypes.objectOf(PropTypes.array).isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DietConflictResolver;
