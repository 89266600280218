import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import cyoSectionShape from '../../../shapes/cyoSectionShape';

import IngredientsPicker from '../../../containers/IngredientsPicker';
import IngredientsSectionHeader from '../../../containers/IngredientsSectionHeader';
import IngredientsSection from '../../../containers/MenuDetails/IngredientsSection';
import OrderItemPrice from '../../../containers/OrderItemPrice';
import Button from '../../Button';
import CarbonFootprint from '../../CarbonFootprint';
import DietButton from '../../DietButton';
import LimitNotification from '../../LimitNotification';

import { BASE_SECTION_ID } from '../../../settings';

import './MenuDetailsView.css';

function MenuDetailsView(props) {
  const {
    menuItemId,
    menuItemName,
    baseId,
    sections,
    limitNotification,
    isOrderPreviewRequested,
    onLimitExhausted,
    onOrderItemAdd,
    isBaseSectionSelectable,
  } = props;

  return (
    <div className="MenuDetailsView">
      <div className="MenuContentFrame">
        <div className="MenuDetailsContentHeader">
          <Typography variant="h2" color="secondary.dark">
            {menuItemName}
          </Typography>
          <Typography variant="subtitle2" color="grey.main" className="MenuDetailsHeaderDesc">
            You may make a maximum of 2 substitutions (and unlimited additions!)
          </Typography>
        </div>
        <CarbonFootprint />
        <DietButton type="toggle" />
        <LimitNotification {...limitNotification} />
        {sections.map((section) => (
          <div key={section.id}>
            <IngredientsSectionHeader menuItemId={menuItemId} sectionId={section.id} />
            {section.id === BASE_SECTION_ID && isBaseSectionSelectable ? (
              <IngredientsPicker
                menuItemId={menuItemId}
                sectionId={section.id}
                excludeNullBase
                size="Minimal"
                saveAutomatically
              />
            ) : (
              <IngredientsSection
                menuItemId={menuItemId}
                baseId={baseId}
                sectionId={section.id}
                limitNotification={limitNotification}
                onLimitExhausted={onLimitExhausted}
              />
            )}
          </div>
        ))}
      </div>
      <div className="ActionButton">
        <Button
          className="Split btn-next"
          loading={isOrderPreviewRequested}
          onClick={onOrderItemAdd}
        >
          <span className="Text">
            <span>Next</span>
            <span className="AttentionSeeker">
              <OrderItemPrice />
            </span>
          </span>
        </Button>
      </div>
    </div>
  );
}

MenuDetailsView.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  menuItemName: PropTypes.string.isRequired,
  baseId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(cyoSectionShape).isRequired,
  isOrderPreviewRequested: PropTypes.bool.isRequired,
  limitNotification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    limit: PropTypes.number,
    extra: PropTypes.bool,
    isRemove: PropTypes.bool,
    clearLimitNotification: PropTypes.func.isRequired,
  }),
  onLimitExhausted: PropTypes.func.isRequired,
  onOrderItemAdd: PropTypes.func.isRequired,
  isBaseSectionSelectable: PropTypes.bool.isRequired,
};

MenuDetailsView.defaultProps = {
  limitNotification: null,
};

export default MenuDetailsView;
