import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { setRegistrationFirstName, setRegistrationLastName } from '../../../actions/user';
import statusEnum from '../../../enums/statusEnum';
import * as selectors from '../../../sagas/selectors';

import Button from '../../Button';
import Checkbox from '../../Checkbox';
import InputAlertText from '../../InputAlertText';
import PasswordInput from '../../PasswordInput';

import { auth2Service } from '../../../services/api';
import * as settings from '../../../settings';

import './Register.css';

function RegisterDetails() {
  const dispatch = useDispatch();
  const firstName = useSelector(selectors.getRegistrationFirstName);
  const lastName = useSelector(selectors.getRegistrationLastName);
  const email = useSelector(selectors.getRegistrationEmail);
  const { verifyRecaptcha } = useSelector(selectors.getApiStatus);
  const isRecaptchaEnabled = useSelector(selectors.getIsRecaptchaEnabled);
  const recaptchaRef = useRef();

  const [isChecked, setChecked] = useState(false);
  const [isMarketingOptInChecked, setMarketingOptInChecked] = useState(true);
  const [isRecaptchaSuccess, setIsRecaptchaSuccess] = useState(false);
  const [password, setPassword] = useState('');

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);

  /* Determine whether the submit button is disabled based on recaptcha */
  const isSubmitDisabled = !isChecked || (isRecaptchaEnabled && !isRecaptchaSuccess);

  const onCheckboxClick = () => setChecked(!isChecked);

  const onMarketingOpCheckboxClick = () => setMarketingOptInChecked(!isMarketingOptInChecked);

  const validatePassword = () => {
    if (password.length < 6) return false;
    if (/[a-z]/.test(password) === false) return false;
    if (/[A-Z]/.test(password) === false) return false;
    if (/[0-9]/.test(password) === false) return false;

    return true;
  };

  const validateFirstName = () => firstName.length > 0;

  const validateLastName = () => lastName.length > 0;

  const handleFirstNameChange = (event) =>
    dispatch(setRegistrationFirstName({ firstName: event.target.value }));

  const handleLastNameChange = (event) =>
    dispatch(setRegistrationLastName({ lastName: event.target.value }));

  const handleFormSubmit = (event) => {
    event.preventDefault();

    setIsPasswordValid(validatePassword());
    setIsFirstNameValid(validateFirstName());
    setIsLastNameValid(validateLastName());

    if (isPasswordValid && isFirstNameValid && isLastNameValid) {
      const stateOptions = {
        email,
        password,
      };

      dispatch(
        auth2Service.signUp.requestActionCreator({
          email,
          password,
          firstName,
          lastName,
          stateOptions,
          marketingOptIn: isMarketingOptInChecked,
          country: settings.COUNTRY_CODE,
        }),
      );
    }
  };

  const handleRecaptcha = (value) => {
    /* The recaptcha returns null if it expired or errored */
    if (!value) {
      setIsRecaptchaSuccess(false);
    } else {
      dispatch(
        auth2Service.verifyRecaptcha.requestActionCreator({
          recaptchaValue: value,
        }),
      );
    }
  };

  useEffect(() => {
    if (verifyRecaptcha === statusEnum.SUCCESS) {
      setIsRecaptchaSuccess(true);
    } else if (verifyRecaptcha === statusEnum.ERROR) {
      setIsRecaptchaSuccess(false);
      recaptchaRef.current.reset();
    }
  }, [verifyRecaptcha]);

  return (
    <form className="Register" onSubmit={handleFormSubmit}>
      <Typography
        variant="h1"
        color="secondary.light"
        className="SignInTitle visible-lg visible-md"
        fontWeight="900"
      >
        Create Account
      </Typography>
      <Typography variant="body1" className="user-email padded-bottom bold" color="primary">
        {email}
      </Typography>
      <div>
        <ul className="name margin-bottom">
          <li>
            <Typography variant="subtitle1" color="secondary.light" className="EmailLabel">
              First name
            </Typography>
            <input
              className="form-register"
              type="text"
              placeholder="Sunny"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </li>
          <li>
            <Typography variant="subtitle1" color="secondary.light" className="EmailLabel">
              Last name
            </Typography>
            <input
              className="form-register"
              type="text"
              placeholder="Smith"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </li>
        </ul>
        <div className={classnames({ 'input-alert': !isPasswordValid })}>
          <Typography variant="subtitle1" color="secondary.light" className="EmailLabel">
            Password
          </Typography>
          <PasswordInput
            password={password}
            onPasswordChange={(event) => setPassword(event.target.value)}
          />
        </div>
        {isPasswordValid === false && (
          <InputAlertText noIcon>
            Your password should be 6 characters with 1 lower case, 1 upper case, and 1 number.
          </InputAlertText>
        )}
        {isFirstNameValid === false && (
          <InputAlertText noIcon>The first name field is required.</InputAlertText>
        )}
        {isLastNameValid === false && (
          <InputAlertText noIcon>The last name field is required.</InputAlertText>
        )}
        {verifyRecaptcha === statusEnum.ERROR && (
          <InputAlertText noIcon>Unable to verify recaptcha, please try again</InputAlertText>
        )}
        {isRecaptchaEnabled && (
          <div className="RecaptchaContainer">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={settings.RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
          </div>
        )}
        <Checkbox
          isChecked={isMarketingOptInChecked}
          label={
            <span>
              Join our newsletter to enjoy regular exclusive offers and up to 50% off your first
              order!
            </span>
          }
          onChange={onMarketingOpCheckboxClick}
        />
        <Checkbox
          isChecked={isChecked}
          label={
            <span>
              I agree to the&nbsp;
              <a href="/terms-and-conditions/">Terms &amp; Conditions</a>
              &nbsp;and&nbsp;
              <a href={`/privacy-policy/${settings.COUNTRY_CODE.toLowerCase()}/`}>Privacy Policy</a>
            </span>
          }
          onChange={onCheckboxClick}
        />
        <Button disabled={isSubmitDisabled} className="btn-primary">
          Sign up
        </Button>
      </div>
    </form>
  );
}

RegisterDetails.propTypes = {
  /* React Router props */
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default RegisterDetails;
