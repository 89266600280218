import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { updateLabel } from '../../../actions/orderItem';

function OrderItemLabelInput({ id }) {
  const dispatch = useDispatch();
  const personalSetting = useSelector((state) =>
    state.orderItem.personalSettings.find((value) => value.id === id),
  );

  const onLabelChange = (value) => dispatch(updateLabel({ id, value }));

  return (
    <div>
      <Typography variant="h6" component="p">
        Name on label
      </Typography>
      <input
        className="OrderInfo"
        type="text"
        placeholder="e.g. Sunny Smith"
        maxLength={15}
        value={personalSetting.label}
        onChange={(event) => onLabelChange(event.target.value)}
      />
    </div>
  );
}

OrderItemLabelInput.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrderItemLabelInput;
