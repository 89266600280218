import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import DatePicker from 'antd/lib/date-picker';
import classnames from 'classnames';
import validator from 'email-validator';
import moment from 'moment';

import { afterSignIn, beforeSignIn } from '../../../actions/user';
import statusEnum from '../../../enums/statusEnum';
import { getCurrentLocation, getUser } from '../../../sagas/selectors';
import phoneNumberSeparator from '../../../utils/phoneNumberUtils';

import Button from '../../Button';
import InputAlertText from '../../InputAlertText';
import PasswordInput from '../../PasswordInput';
import Select from '../../Select';
import EditPartnerEmail from '../EditPartnerEmail';

import { auth2Service, oauthService } from '../../../services/api';

import './EditAccountForm.css';

function EditAccountForm() {
  const { errors, ...currentState } = useSelector(getUser);
  const location = useSelector(getCurrentLocation);

  const phoneFormat = useMemo(
    () => phoneNumberSeparator(currentState.phone ?? ''),
    [currentState.phone],
  );

  const [partnerEmail, setPartnerEmail] = useState(currentState.partnerEmail ?? '');
  const [firstName, setFirstName] = useState(currentState.firstName);
  const [lastName, setLastName] = useState(currentState.lastName);
  const [gender, setGender] = useState(currentState.gender);
  const [dob, setDob] = useState(moment(currentState.dob));
  const [countryCode, setCountryCode] = useState(phoneFormat.countryCode ?? '');
  const [phone, setPhone] = useState(phoneFormat.phoneNumber ?? '');
  const [marketingOptIn, setMarketingOptIn] = useState(currentState.marketingOptIn ?? false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const userApiStatus = currentState.status;

  useEffect(() => {
    if (userApiStatus.changePassword === statusEnum.SUCCESS) {
      setOldPassword('');
      setNewPassword('');
    }
  }, [userApiStatus.changePassword]);

  const obtainErrorMessage = (endpoint) => {
    const apiError = errors.find(({ endpointName }) => endpointName === endpoint);
    if (apiError) {
      // Default format of view and serializer error has different structure
      const errorMessage = apiError.error?.message || apiError.error;

      if (typeof errorMessage === 'string') {
        return errorMessage;
      }
      if (Array.isArray(errorMessage)) {
        return errorMessage[0];
      }
      return JSON.stringify(errorMessage);
    }
    return null;
  };
  const isPartnerEmailValid = validator.validate(partnerEmail) || partnerEmail === '';
  const isFirstNameValid = firstName?.length > 0;
  const isLastNameValid = lastName?.length > 0;
  const isPhoneValid = phone?.length > 0;
  const isCountryCodeValid = countryCode?.length > 1;
  const isPasswordValid = (() => {
    if (newPassword?.length > 0) {
      if (newPassword?.length < 6) return false;
      if (/[a-z]/.test(newPassword) === false) return false;
      if (/[A-Z]/.test(newPassword) === false) return false;
      if (/[0-9]/.test(newPassword) === false) return false;
      return true;
    }
    return true;
  })();
  const partnerEmailApiError = (() => {
    let apiError = obtainErrorMessage('updatePartnerEmail');
    if (apiError) {
      if (/Partner email domain does not exist/i.test(apiError)) {
        apiError = 'The email domain is not our partner.';
      } else if (/Partner email domain has been disabled/i.test(apiError)) {
        apiError = 'The email domain has been disabled.';
      }
      return apiError;
    }
    return null;
  })();
  const phoneApiError = obtainErrorMessage('createPhone');
  const passwordApiError = obtainErrorMessage('changePassword');
  const profileApiError = obtainErrorMessage('updateProfile');

  const isPartnerEmailChanged = partnerEmail !== (currentState.partnerEmail ?? '');
  const isFirstNameChanged = firstName !== currentState.firstName;
  const isLastNameChanged = lastName !== currentState.lastName;
  const isGenderChanged = gender !== currentState.gender;
  const isDobChanged =
    dob?.isValid() !== moment(currentState.dob).isValid() ||
    (dob?.isValid() && !dob?.isSame(moment(currentState.dob), 'day'));
  const isMarketingOptInChanged = marketingOptIn !== (currentState.marketingOptIn ?? false);
  const isProfileChanged =
    isFirstNameChanged ||
    isLastNameChanged ||
    isGenderChanged ||
    isDobChanged ||
    isMarketingOptInChanged;
  const isCountryCodeChanged = countryCode !== (phoneFormat.countryCode ?? '');
  const isPhoneChanged = phone !== (phoneFormat.phoneNumber ?? '');
  const isPasswordChanged = oldPassword !== '' && newPassword !== '';
  const isSomethingChanged =
    isPartnerEmailChanged || isProfileChanged || isCountryCodeChanged || isPhoneChanged;
  const isFormValid =
    isPartnerEmailValid &&
    isFirstNameValid &&
    isLastNameValid &&
    isPhoneValid &&
    isCountryCodeValid;

  const dispatch = useDispatch();

  const onCountryCodeChange = (event) => {
    const { value } = event.target;

    if (/^\+(\d+|-| |)+$/.test(value) === false) return;

    setCountryCode(value);
  };
  const onPhoneChange = (event) => {
    const { value } = event.target;

    if (/^(\d+|-|\+| |)+$/.test(value) === false) return;

    setPhone(value);
  };
  const onSave = () => {
    if (!isFormValid) return false;

    if (isPartnerEmailChanged) {
      dispatch(auth2Service.updatePartnerEmail.requestActionCreator({ partnerEmail }));
    }
    if (isProfileChanged) {
      dispatch(
        auth2Service.updateProfile.requestActionCreator({
          firstName,
          lastName,
          dob: dob?.isValid() ? dob : null,
          gender,
          marketingOptIn,
        }),
      );
    }
    if (isPhoneChanged || isCountryCodeChanged) {
      dispatch(beforeSignIn({ pathname: location.pathname, search: location.search }));
      dispatch(afterSignIn({ pathname: location.pathname, search: location.search }));
      const parsedNumber = `${countryCode}${(phone.match(/\d/g) || []).join('')}`;
      const stateOptions = {
        pendingPhone: parsedNumber,
      };
      dispatch(
        oauthService.createPhone.requestActionCreator({
          body: { number: parsedNumber },
          stateOptions,
        }),
      );
    }
    return true;
  };
  const onPasswordSave = () => {
    if (!isPasswordValid) return false;

    if (isPasswordChanged) {
      dispatch(auth2Service.changePassword.requestActionCreator({ oldPassword, newPassword }));
    }
    return true;
  };
  const onCancel = () => {
    setPartnerEmail(currentState.partnerEmail ?? '');
    setFirstName(currentState.firstName);
    setLastName(currentState.lastName);
    setGender(currentState.gender);
    setDob(moment(currentState.dob));
    setPhone(currentState.phone ?? '');
  };

  return (
    <div className="EditAccountForm">
      <div className="EditAccountFormRow">
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Email
          </Typography>
          <input
            className="edit-account MainEmail"
            type="email"
            value={currentState.email}
            readOnly
          />
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Partner email
          </Typography>
          <EditPartnerEmail
            partnerEmail={partnerEmail}
            partnerEmailStatus={currentState.partnerEmailStatus}
            isChanged={isPartnerEmailChanged}
            isDefault={currentState.partnerEmail === null}
            isError={!isPartnerEmailValid || partnerEmailApiError}
            onPartnerEmailChange={(event) => setPartnerEmail(event.target.value)}
          />
          {!isPartnerEmailValid && (
            <InputAlertText noIcon>Please enter a valid partner email.</InputAlertText>
          )}
          {isPartnerEmailValid && partnerEmailApiError && (
            <InputAlertText noIcon>{partnerEmailApiError}</InputAlertText>
          )}
          {isPartnerEmailChanged && isPartnerEmailValid && !partnerEmailApiError && (
            <Typography variant="subtitle2" color="primary" className="InputInfo">
              Verification link will be sent.
            </Typography>
          )}
        </div>
      </div>
      <div className="EditAccountFormRow">
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            First name
          </Typography>
          <input
            className={classnames(
              'edit-account',
              { 'input-alert': !isFirstNameValid || profileApiError },
              { changed: isFirstNameChanged },
            )}
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          {!isFirstNameValid && (
            <InputAlertText noIcon>Please enter your first name.</InputAlertText>
          )}
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Last name
          </Typography>
          <input
            className={classnames(
              'edit-account',
              { 'input-alert': !isLastNameValid || profileApiError },
              { changed: isLastNameChanged },
            )}
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
          {!isLastNameValid && <InputAlertText noIcon>Please enter your last name.</InputAlertText>}
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Gender
          </Typography>
          <Select
            id="gender"
            className={classnames(
              'edit-account',
              { 'input-alert': !profileApiError },
              { changed: isGenderChanged },
            )}
            value={gender}
            onChange={(value) => setGender(value)}
          >
            <option value="" selected disabled hidden>
              Select
            </option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </Select>
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Birthday
          </Typography>
          <DatePicker
            className={classnames(
              'edit-account',
              { 'input-alert': !profileApiError },
              { changed: isDobChanged },
            )}
            value={dob?.isValid() ? dob : null}
            onChange={(value) => setDob(value)}
            showToday={false}
            showTime={false}
          />
        </div>
        {isProfileChanged && profileApiError && (
          <InputAlertText noIcon>{profileApiError}</InputAlertText>
        )}
      </div>
      <div className="EditAccountFormRow">
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Contact number
          </Typography>
          <ul className="phone-no">
            <li>
              <input
                className={classnames(
                  'edit-account',
                  { 'input-alert': !isCountryCodeValid },
                  { changed: isCountryCodeChanged },
                )}
                type="text"
                value={countryCode}
                onChange={onCountryCodeChange}
              />
            </li>
            <li>
              <input
                className={classnames(
                  'edit-account',
                  { 'input-alert': !isPhoneValid || phoneApiError },
                  { changed: isPhoneChanged },
                )}
                type="text"
                value={phone}
                onChange={onPhoneChange}
              />
            </li>
          </ul>
          {(!isPhoneValid || !isCountryCodeValid) && (
            <InputAlertText noIcon>Please enter a valid phone number.</InputAlertText>
          )}
          {isCountryCodeValid && isPhoneValid && phoneApiError && (
            <InputAlertText noIcon>{phoneApiError}</InputAlertText>
          )}
          {(isCountryCodeChanged || isPhoneChanged) &&
            isCountryCodeValid &&
            isPhoneValid &&
            !phoneApiError && (
              <Typography variant="subtitle2" color="primary" className="InputInfo">
                Verification link will be sent.
              </Typography>
            )}
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Marketing email
          </Typography>
          <div className="MarketingEmailRadio">
            <label htmlFor="marketingOptInTrue">
              <input
                type="radio"
                value="true"
                name="marketingOptIn"
                id="marketingOptInTrue"
                defaultChecked={marketingOptIn}
                onClick={() => setMarketingOptIn(true)}
              />
              Yes, I&apos;m in!
            </label>
            <label htmlFor="marketingOptInFalse">
              <input
                type="radio"
                value=""
                name="marketingOptIn"
                id="marketingOptInFalse"
                defaultChecked={!marketingOptIn}
                onClick={() => setMarketingOptIn(false)}
              />
              No, I&apos;ll pass.
            </label>
          </div>
        </div>
      </div>
      <div className="EditAccountFormButtonRow">
        <Button
          className="btn-primary"
          disabled={!(isSomethingChanged && isFormValid)}
          onClick={onSave}
        >
          Save
        </Button>
        <Button className="btn-secondary" disabled={!isSomethingChanged} onClick={onCancel}>
          Cancel
        </Button>
      </div>

      <ul className="title-function">
        <li>
          <Typography variant="h3" color="secondary.light" className="AccountTitle">
            Change Password
          </Typography>
        </li>
      </ul>
      <div className="EditAccountFormLongRow">
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            Old password
          </Typography>
          <PasswordInput
            inputClassName={classnames(
              'edit-account',
              { 'input-alert': !isPasswordValid || passwordApiError },
              { changed: isPasswordChanged },
            )}
            placeholder="Old password"
            password={oldPassword}
            onPasswordChange={(event) => setOldPassword(event.target.value)}
          />
          {!isPasswordValid && <InputAlertText noIcon>Incorrect password.</InputAlertText>}
          {isPasswordValid && passwordApiError && (
            <InputAlertText noIcon>{passwordApiError}</InputAlertText>
          )}
        </div>
        <div className="EditAccountFormColumn">
          <Typography variant="subtitle1" color="secondary.light" className="InputLabel">
            New password
          </Typography>
          <PasswordInput
            inputClassName={classnames(
              'edit-account',
              { 'input-alert': !isPasswordValid || passwordApiError },
              { changed: isPasswordChanged },
            )}
            placeholder="Enter a new password"
            password={newPassword}
            onPasswordChange={(event) => setNewPassword(event.target.value)}
          />
          <Typography variant="subtitle2" color="primary" className="InputInfo">
            Min. 6 characters incl. 1 uppercase, 1 lowercase, and 1 number.
          </Typography>
        </div>
        <div className="EditAccountFormShortColumn">
          <Button
            className="btn-primary"
            disabled={!(isPasswordChanged && isPasswordValid)}
            onClick={onPasswordSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditAccountForm;
