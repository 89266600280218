import React from 'react';
import PropTypes from 'prop-types';

import rewardShape from '../../shapes/rewardShape';

import LoyaltyReward from '../LoyaltyReward';
import SectionSeparator from '../SectionSeparator';

import './LoyaltyRewardsList.css';

function LoyaltyRewardsList(props) {
  const { size, showHeader, rewards, onRedeem } = props;

  return (
    <div className="LoyaltyRewardsList">
      {showHeader && <SectionSeparator typographyVariant="h4">REWARDS</SectionSeparator>}
      {rewards.map(
        (reward) =>
          /* Forced to add this because for some reason it was looping over corrupted entries */
          reward && (
            <LoyaltyReward
              key={reward?.uuid}
              size={size}
              reward={reward}
              onRedeem={() => onRedeem(reward)}
            />
          ),
      )}
    </div>
  );
}

LoyaltyRewardsList.propTypes = {
  size: PropTypes.oneOf(['Small', 'Medium']).isRequired,
  showHeader: PropTypes.bool.isRequired,
  rewards: PropTypes.arrayOf(rewardShape).isRequired,
  onRedeem: PropTypes.func.isRequired,
};

export default LoyaltyRewardsList;
