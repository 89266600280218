import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';
import { push } from 'redux-first-history';

import * as historyActions from '../../actions/history';
import * as userActions from '../../actions/user';
import * as selectors from '../../sagas/selectors';

import Button from '../Button';

import './Account.css';

function Account() {
  const dispatch = useDispatch();
  const loggedIn = !!useSelector(selectors.getUserToken);
  const landingPageHistory = useSelector(selectors.getLandingPageHistory);
  const firstName = useSelector(selectors.getFirstName);
  const isDeliveryAvailable = useSelector(selectors.getIsDeliveryAvailable);

  const onSignOutClick = () => {
    dispatch(userActions.signOut());
  };

  useEffect(() => {
    if (loggedIn === false) {
      if (!landingPageHistory[landingPageHistory.length - 1]) {
        dispatch(push({ pathname: '/' }));
      } else {
        const landingPageSlug = landingPageHistory[landingPageHistory.length - 1];
        historyActions.goBackTo(`/site/${landingPageSlug}`);
      }
    }
  }, [dispatch, landingPageHistory, loggedIn]);

  return (
    <div className="Account">
      <div className="DesktopBarWrapper hidden-sm hidden-xs">
        <div className="DesktopBar">
          <Typography variant="h2" fontSize="3em">
            Hello {firstName}
          </Typography>
          <Button onClick={onSignOutClick} className="logout-link">
            Log out
          </Button>
          <ul>
            <li>Account details</li>
            <li>
              <NavLink to="/account/edit">Account details</NavLink>
            </li>
            <li>
              <NavLink to="/account/paymentMethods">Saved cards</NavLink>
            </li>
            {isDeliveryAvailable && (
              <li>
                <NavLink to="/account/addresses">Saved addresses</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/account/orders">Order history</NavLink>
            </li>
            <li>
              <NavLink to="/account/favorites">Favourites</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div id="AccountMainWrapper" className="AccountMainWrapper">
        <div className="AccountMain">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Account;
