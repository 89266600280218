import React from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { Typography } from '@mui/material';
import L from 'leaflet';
import PropTypes from 'prop-types';

import gglocationShape from '../../shapes/gglocationShape';

import Image from '../../containers/Image';
import GglocationMapMobile from '../../containers/PickupTimeSelector/GglocationMapMobile';
import ScheduleTimePicker from '../../containers/PickupTimeSelector/ScheduleTimePicker';
import Footer from '../Landing/Footer';
import Spinner from '../Spinner';
import GglocationInfo from './GglocationInfo';

import IconLocale from '../../images/icon-locale-large.svg';

import './PickupTimeSelector.css';

const iconLocale = L.icon({
  iconUrl: IconLocale,
  iconSize: [36, 48],
  iconAnchor: [18, 24],
  popupAnchor: [0, 0],
});

function PickupTimeSelector(props) {
  const {
    gglocation,
    isDeliveryOrder,
    isAddAddressRequested,
    coordinates,
    address,
    openingHours,
    lastOrderTime,
  } = props;

  /* Display spinner while the new address is being added */
  if (isDeliveryOrder && (!address || isAddAddressRequested)) {
    return <Spinner center />;
  }

  return (
    <>
      <div className="PickupTimeSelector">
        {isDeliveryOrder && coordinates && (
          <div className="ImageStore">
            <MapContainer
              key={Object.values(coordinates)}
              center={coordinates}
              zoom={20}
              style={{ height: '100%' }}
              dragging={false}
              zoomControl={false}
            >
              <Marker icon={iconLocale} position={coordinates}>
                <Tooltip permanent offset={[0, 0]} direction="right" opacity={1}>
                  <div>
                    <Typography variant="subtitle1" fontWeight="600">
                      {address.label}
                    </Typography>
                  </div>
                </Tooltip>
              </Marker>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          </div>
        )}
        {!isDeliveryOrder && (
          <div className="ImageStore">
            <Image
              id={gglocation?.images?.banner}
              source="gglocationsImages"
              context="original"
              oneSize
            />
            <GglocationMapMobile coordinates={gglocation?.coordinates} />
          </div>
        )}
        <div className="ContentContainer">
          {isDeliveryOrder && (
            <Typography variant="h1" color="secondary.light" className="title" fontWeight="900">
              DELIVER TO
            </Typography>
          )}
          {gglocation && (
            <GglocationInfo
              name={isDeliveryOrder ? address.label : gglocation.name}
              address1={isDeliveryOrder ? '' : gglocation.address1}
              address2={isDeliveryOrder ? '' : gglocation.address2}
              isDeliveryOrder={isDeliveryOrder}
              coordinates={gglocation?.coordinates}
              gglocation={gglocation}
              openingHours={openingHours}
              lastOrderTime={lastOrderTime}
            />
          )}
          <ScheduleTimePicker />
        </div>
      </div>
      <Footer />
    </>
  );
}

PickupTimeSelector.propTypes = {
  gglocation: gglocationShape.isRequired,
  isDeliveryOrder: PropTypes.bool.isRequired,
  isAddAddressRequested: PropTypes.bool.isRequired,
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  address: PropTypes.objectOf(PropTypes.object),
  openingHours: PropTypes.string,
  lastOrderTime: PropTypes.string,
};

PickupTimeSelector.defaultProps = {
  coordinates: {
    lat: null,
    lng: null,
  },
  address: null,
  openingHours: null,
  lastOrderTime: null,
};

export default PickupTimeSelector;
