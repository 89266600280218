import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import favoriteMenuItemShape from '../../../shapes/favoriteMenuItemShape';
import { landingPageMenuStyles } from '../../../shapes/landingPageStyleShapes';
import menuItemShape from '../../../shapes/menuItemShape';

import MenuItem from '../../../containers/Menu/MenuItem';

import './MenuCategory.css';

function MenuCategory(props) {
  const {
    className,
    navigationKey,
    name,
    description,
    menuItems,
    landingPageStyles,
    scrollable,
    visible,
    hideUnavailable,
    onClick,
    onInit,
  } = props;

  return (
    <div id={navigationKey} className={classnames('section', className)} ref={onInit}>
      {name && (
        <div className="category-name kale">
          <Typography
            variant="h5"
            fontWeight="700"
            color="secondary.light"
            style={landingPageStyles && landingPageStyles.categoryTitleStyle}
          >
            {name}
          </Typography>
        </div>
      )}
      <div className="category-desc kale">
        <p style={landingPageStyles && landingPageStyles.categoryDescStyle}>{description}</p>
      </div>
      <ul className={classnames('MenuCategory', { scrollable })}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={`${menuItem.id}_${index}`} // eslint-disable-line react/no-array-index-key
            data={menuItem}
            landingPageStyles={landingPageStyles && landingPageStyles.menuItemStyle}
            visible={visible}
            hideUnavailable={hideUnavailable}
            onClick={onClick}
          />
        ))}
      </ul>
    </div>
  );
}

MenuCategory.propTypes = {
  className: PropTypes.string,
  navigationKey: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([menuItemShape, favoriteMenuItemShape]))
    .isRequired,
  scrollable: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  hideUnavailable: PropTypes.bool,
  landingPageStyles: landingPageMenuStyles.isRequired,
  onClick: PropTypes.func.isRequired,
  onInit: PropTypes.func,
};

MenuCategory.defaultProps = {
  className: '',
  navigationKey: null,
  name: null,
  description: null,
  visible: true,
  hideUnavailable: false,
  onInit: null,
};

export default MenuCategory;
