import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import loadingSalad from '../LoadingScreen/loading-heybo.gif';

import './Spinner.css';

function Spinner({ center, className, logoSpinner }) {
  if (logoSpinner) {
    return (
      <div className={classnames({ center }, 'Spinner logoSpinner', className)}>
        <img className="SaladAnimation" alt="" src={loadingSalad} />
      </div>
    );
  }

  return (
    <div className={classnames({ center }, 'Spinner', className)}>
      <div className="Spinner1 Spinner-child" />
      <div className="Spinner2 Spinner-child" />
      <div className="Spinner3 Spinner-child" />
      <div className="Spinner4 Spinner-child" />
      <div className="Spinner5 Spinner-child" />
      <div className="Spinner6 Spinner-child" />
      <div className="Spinner7 Spinner-child" />
      <div className="Spinner8 Spinner-child" />
      <div className="Spinner9 Spinner-child" />
      <div className="Spinner0 Spinner-child" />
      <div className="Spinner1 Spinner-child" />
      <div className="Spinner2 Spinner-child" />
    </div>
  );
}

Spinner.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  logoSpinner: PropTypes.bool,
};

Spinner.defaultProps = {
  center: false,
  className: '',
  logoSpinner: true,
};

export default Spinner;
