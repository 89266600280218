import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ec812d',
      light: '#fee5d4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      light: '#ffb200',
      dark: '#572021',
      pink: '#eb4c99',
      orange: '#ff6d6d',
      contrastText: '#ec812d',
    },
    grey: {
      main: '#363534',
      light: '#807e7a',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF1C1E',
      contrastText: '#FFD5D5',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '10px',
        textTransform: 'none',
      },
      label: {
        fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      },
    },
  },
  typography: {
    fontFamily: ['Work Sans', 'sans-serif', 'serif'].join(),
    button: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '0.95em',
      textTransform: 'unset',
    },
    bold: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
    },
    h1: {
      fontFamily: ['GT Ultra Standard Ultra', 'GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '3em',
    },
    h2: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '2em',
    },
    h3: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '1.5em',
      marginBottom: '0px',
    },
    h4: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '1.3em',
    },
    h5: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '1.15em',
    },
    h6: {
      fontFamily: ['GT Ultra Standard Black', 'sans-serif'].join(),
      fontSize: '1.0em',
      fontWeight: '400',
    },
    body1: {
      fontFamily: ['Work Sans', 'sans-serif'].join(),
      fontSize: '1.2em',
    },
    body2: {
      fontFamily: ['Work Sans', 'sans-serif'].join(),
      fontSize: '12px',
    },
    subtitle1: {
      fontFamily: ['Work Sans', 'sans-serif'].join(),
      fontSize: '1em',
    },
    subtitle2: {
      fontFamily: ['Work Sans', 'sans-serif'].join(),
      fontSize: '14px',
    },
    calafia1: {
      fontFamily: ['Calafia', 'sans-serif'].join(),
      fontSize: '4.8em',
      fontWeight: '400',
      transform: 'rotate(-6deg)',
      '@media (max-width: 992px)': {
        fontSize: '3.1em',
      },
    },
    calafia2: {
      fontFamily: ['Calafia', 'sans-serif'].join(),
      fontSize: '3.2em',
      fontWeight: '400',
      transform: 'rotate(-6deg)',
      '@media (max-width: 992px)': {
        fontSize: '2.5em',
      },
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#363534',
        },
      },
    },
  },
});

export default theme;
