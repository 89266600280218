import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as errorResolutionActions from '../../actions/errorResolution';
import * as historyActions from '../../actions/history';
import * as loyaltyActions from '../../actions/loyalty';
import * as orderActions from '../../actions/order';
import * as orderItemActions from '../../actions/orderItem';
import * as userActions from '../../actions/user';
import orderItemStatusEnum from '../../enums/orderItemStatusEnum';
import orderTypeEnum from '../../enums/orderTypeEnum';
import * as errorResolutionUtils from '../../utils/errorResolutionUtils';

import errorShape from '../../shapes/errorShape';
import routerShape, { routerLocationShape } from '../../shapes/routerShape';

import Button from '../../components/Button';
import PricingError from '../../components/PricingError';
import withRouter from '../WithRouter';

class PricingErrorContainer extends Component {
  static handleReloadApp() {
    errorResolutionUtils.reloadApp();
  }

  static propTypes = {
    error: errorShape,
    warning: errorShape,
    hasOrderItems: PropTypes.bool.isRequired,
    orderType: PropTypes.oneOf([orderTypeEnum.INSTANT, orderTypeEnum.SCHEDULED]).isRequired,
    isOrderItemInitial: PropTypes.bool.isRequired,
    selectedPromoCode: PropTypes.bool,
    goToMenu: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,
    beforeSignIn: PropTypes.func.isRequired,
    changeOrderTime: PropTypes.func.isRequired,
    changeOrderGglocation: PropTypes.func.isRequired,
    changeOrderAddress: PropTypes.func.isRequired,
    previewOrderDispatch: PropTypes.func.isRequired,
    removeDiscount: PropTypes.func.isRequired,
    resetOrder: PropTypes.func.isRequired,
    resetOrderItem: PropTypes.func.isRequired,
    unsetSelectedPromoCode: PropTypes.func.isRequired,

    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  static defaultProps = {
    selectedPromoCode: null,
    error: null,
    warning: null,
  };

  get isModalOpen() {
    const { suggestedAction } = this;

    return suggestedAction !== null;
  }

  /* This is a good place to overwrite error messages. */
  get errorMessage() {
    const { error, orderType } = this.props;

    if (!error) {
      return null;
    }

    /* Handle delivery order scheduling elsewhere */
    if (
      error.message === 'No stores available to handle the order' &&
      orderType === orderTypeEnum.SCHEDULED
    ) {
      return null;
    }

    /* Invalid token error is handled by another component. */
    if (error.message === 'Invalid token.') {
      return null;
    }

    if (error.message === 'Store busy') {
      return 'Selected location is unavailable';
    }

    return error.message;
  }

  get warningMessage() {
    const { warning } = this.props;

    return warning && warning.message;
  }

  get isGglocationTypeInvalid() {
    return this.errorMessage && this.errorMessage.includes('Invalid gglocation type');
  }

  get showUnsetPromoCode() {
    const { warningMessage } = this;
    const { selectedPromoCode } = this.props;

    return (
      [
        'Discount is not valid at this location',
        'Order does not contain items to qualify for discount',
      ].includes(warningMessage) && !!selectedPromoCode
    );
  }

  get showSignIn() {
    return this.errorMessage === 'Invalid user';
  }

  get showChangeAddress() {
    return this.errorMessage === 'No stores available to handle the order';
  }

  get showChangeGglocation() {
    return ['Selected location is unavailable', 'Store is closed'].includes(this.errorMessage);
  }

  get showChangeOrderTime() {
    return this.errorMessage === 'Store scheduled time not available';
  }

  get showRemoveItem() {
    const { error, isOrderItemInitial } = this.props;

    return error && !isOrderItemInitial;
  }

  get showClearCart() {
    const { error, hasOrderItems } = this.props;

    return error && hasOrderItems;
  }

  get suggestedAction() {
    if (!this.errorMessage) {
      return null;
    }

    if (this.showUnsetPromoCode) {
      return 'UNSET_PROMO_CODE';
    }

    if (this.showSignIn) {
      return 'SIGN_IN';
    }

    if (this.showChangeGglocation) {
      return 'CHANGE_ORDER_GGLOCATION';
    }

    if (this.showChangeAddress) {
      return 'CHANGE_ADDRESS';
    }

    if (this.showChangeOrderTime) {
      return 'CHANGE_ORDER_TIME';
    }

    if (this.showRemoveItem) {
      return 'REMOVE_ITEM';
    }

    if (this.showClearCart) {
      return 'CLEAR_CART';
    }

    return 'RELOAD_APP';
  }

  handleUnsetPromoCode = () => {
    const { unsetSelectedPromoCode } = this.props;

    unsetSelectedPromoCode();
  };

  handleSignIn = () => {
    const { afterSignIn, beforeSignIn, location, router, removeDiscount } = this.props;
    const { pathname, search } = location;

    removeDiscount();
    beforeSignIn({ pathname, search });
    afterSignIn({ pathname, search });

    router.push('/signIn');
  };

  handleChangeOrderGglocation = () => {
    const { changeOrderGglocation } = this.props;

    changeOrderGglocation();
  };

  handleChangeAddress = () => {
    const { changeOrderAddress } = this.props;

    changeOrderAddress();
  };

  handleChangeOrderTime = () => {
    const { changeOrderTime } = this.props;

    changeOrderTime();
  };

  handleRemoveItem = () => {
    const { resetOrderItem, goToMenu } = this.props;

    goToMenu({});
    resetOrderItem();
  };

  handleClearCart = () => {
    const { resetOrder, goToMenu } = this.props;

    goToMenu({});
    resetOrder();
  };

  handleRetry = () => {
    const { previewOrderDispatch } = this.props;

    previewOrderDispatch();
  };

  renderErrorMessage() {
    const { errorMessage, suggestedAction, isGglocationTypeInvalid } = this;
    const { selectedPromoCode } = this.props;
    const invalidGglocationTypeMsg = isGglocationTypeInvalid ? ' or a different location' : '';

    /* Use this to overwrite pricing error message. */
    switch (suggestedAction) {
      case 'UNSET_PROMO_CODE':
        return (
          <div>
            <p>
              <strong>Promo code removed</strong>
            </p>
            <p>
              Your cart does not meet the requirements for the promo code and it will be removed.
            </p>
          </div>
        );
      case 'SIGN_IN':
        return (
          <div>
            <p>
              <strong>Promo code notice</strong>
            </p>
            <p>You need to be signed in to use {selectedPromoCode}.</p>
          </div>
        );
      default:
        return (
          <div>
            <Typography variant="h3" color="secondary.dark">
              Oops! We’re sorry...
            </Typography>
            <p>{errorMessage}</p>
            <p>
              <span>Please try again or </span>
              {suggestedAction === 'CHANGE_ADDRESS' && <span>pick a different address.</span>}
              {suggestedAction === 'CHANGE_ORDER_GGLOCATION' && (
                <span>pick a different location.</span>
              )}
              {suggestedAction === 'CHANGE_ORDER_TIME' && (
                <span>
                  pick a different time
                  {invalidGglocationTypeMsg}.
                </span>
              )}
              {suggestedAction === 'REMOVE_ITEM' && (
                <span>
                  clear your order item
                  {invalidGglocationTypeMsg}.
                </span>
              )}
              {suggestedAction === 'CLEAR_CART' && (
                <span>
                  pick a different item
                  {invalidGglocationTypeMsg}.
                </span>
              )}
              {suggestedAction === 'RELOAD_APP' && (
                <span>
                  reload app
                  {invalidGglocationTypeMsg}.
                </span>
              )}
            </p>
          </div>
        );
    }
  }

  renderButtons() {
    const { suggestedAction, isGglocationTypeInvalid } = this;

    const retryButton = isGglocationTypeInvalid ? (
      <Button className="btn-primary" onClick={this.handleChangeOrderGglocation}>
        Change location
      </Button>
    ) : (
      <Button className="btn-primary" onClick={this.handleRetry}>
        Retry
      </Button>
    );

    switch (suggestedAction) {
      case 'UNSET_PROMO_CODE':
        return [
          <Button key="okay-promo" className="btn-primary" onClick={this.handleUnsetPromoCode}>
            Okay
          </Button>,
        ];
      case 'SIGN_IN':
        return [
          <Button key="okay-sign-in" className="btn-primary" onClick={this.handleSignIn}>
            Okay
          </Button>,
        ];
      case 'CHANGE_ORDER_GGLOCATION':
        return [
          <Button
            key="change-order-location"
            className="btn-secondary"
            onClick={this.handleChangeOrderGglocation}
          >
            Change location
          </Button>,
          retryButton,
        ];

      case 'CHANGE_ADDRESS':
        return [
          <Button key="change-address" className="btn-secondary" onClick={this.handleChangeAddress}>
            Change Address
          </Button>,
          retryButton,
        ];
      case 'CHANGE_ORDER_TIME':
        return [
          <Button
            key="change-order-time"
            className="btn-secondary"
            onClick={this.handleChangeOrderTime}
          >
            Change time
          </Button>,
          retryButton,
        ];
      case 'REMOVE_ITEM':
        return [
          <Button key="remove-item" className="btn-secondary" onClick={this.handleRemoveItem}>
            Remove item
          </Button>,
          retryButton,
        ];
      case 'CLEAR_CART':
        return [
          <Button key="clear-cart" className="btn-secondary" onClick={this.handleClearCart}>
            Clear cart
          </Button>,
          retryButton,
        ];
      case 'RELOAD_APP':
        return [
          <Button
            key="reload"
            className="btn-secondary"
            onClick={PricingErrorContainer.handleReloadApp}
          >
            Reload app
          </Button>,
          retryButton,
        ];
      default:
        return [];
    }
  }

  render() {
    const { isModalOpen } = this;

    return (
      <PricingError buttons={this.renderButtons()} isModalOpen={isModalOpen}>
        {this.renderErrorMessage()}
      </PricingError>
    );
  }
}

const mapStateToProps = (state) => ({
  isOrderItemInitial: state.orderItem.orderItemStatus === orderItemStatusEnum.INITIAL,
  hasOrderItems: state.order.orderItemsIds.length > 0,
  selectedPromoCode: state.loyalty.selectedPromoCode,
  orderType: state.order.orderType,
  error: state.order.error,
  location: state.router.location,
  warning: state.order.warning,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      afterSignIn: userActions.afterSignIn,
      beforeSignIn: userActions.beforeSignIn,
      changeOrderTime: errorResolutionActions.changeOrderTime,
      changeOrderAddress: errorResolutionActions.changeOrderAddress,
      changeOrderGglocation: errorResolutionActions.changeOrderGglocation,
      previewOrderDispatch: orderActions.previewOrderDispatch,
      removeDiscount: loyaltyActions.removeDiscount,
      resetOrder: orderActions.resetOrder,
      resetOrderItem: orderItemActions.resetOrderItem,
      unsetSelectedPromoCode: loyaltyActions.unsetSelectedPromoCode,
      goToMenu: historyActions.goToMenu,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingErrorContainer));
