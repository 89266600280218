import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import { goHome } from '../../actions/history';
import { hideCart, resetOrder } from '../../actions/order';
import { openDiningChoiceModal } from '../../actions/page';
import { getIsLandingPageOrder } from '../../sagas/selectors';

import Button from '../Button';
import ButtonsRow from '../ButtonsRow/ButtonsRow';
import Modal from '../Modal';

import './DiningChoiceChanger.css';

function DiningChoiceChanger() {
  const [isModalShow, setIsModalShow] = useState(false);

  const isLandingPageOrder = useSelector(getIsLandingPageOrder);

  const dispatch = useDispatch();

  const onResetOrder = () => {
    setIsModalShow(false);

    if (isLandingPageOrder) {
      dispatch(openDiningChoiceModal());
    } else {
      dispatch(resetOrder());
      dispatch(goHome());
      dispatch(hideCart());
    }
  };

  return (
    <>
      <Button
        className="btn-secondary DiningChoiceChangeButton"
        onClick={() => setIsModalShow(true)}
      >
        Change
      </Button>
      <Modal className="DiningChoiceChangeModal" show={isModalShow}>
        <Modal.Body>
          <Typography variant="h3" color="secondary.dark">
            Hold up!
          </Typography>
          <Typography variant="subtitle2" color="grey.main" className="DiningChoiceChangeContent">
            You will need to restart the ordering process if you choose to change pickup/delivery
            locations. Are you sure you want to proceed?
          </Typography>
          <ButtonsRow>
            <Button
              variant="contained"
              className="btn-secondary"
              onClick={() => setIsModalShow(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" className="btn-primary" onClick={() => onResetOrder(false)}>
              Yes
            </Button>
          </ButtonsRow>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DiningChoiceChanger;
