import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import userOrderShape from '../../shapes/userOrderShape';

import Addresses from '../../containers/Account/Addresses';
import Image from '../../containers/Image';
import CurrentOrder from '../../containers/Landing/CurrentOrder';
import LoyaltyRewardsList from '../../containers/LoyaltyRewardsList';
import SelectedReward from '../../containers/SelectedReward';
import HomepageMarketing from '../HomepageMarketing';
import InfiniteScrollLoader from '../InfiniteScrollLoader';
import LoyaltyFab from '../LoyaltyFab';
import LoyaltyTabs from '../LoyaltyTabs';
import SectionSeparator from '../SectionSeparator';
import DiningChoiceSelector from './DiningChoiceSelector';
import EmailVerificationPopUp from './EmailVerificationPopUp';
import Footer from './Footer';

import './Landing.css';

function Landing(props) {
  const {
    isDeliveryOrder,
    hasMoreOngoingOrders,
    userOrders,
    isLoyaltyEnabled,
    onLoadMoreOngoingOrders,
  } = props;

  return (
    <>
      <div id="LandingWrapper" className="LandingWrapper">
        <div className="Landing">
          <EmailVerificationPopUp />
          <div className="LandingLeftContainer">
            <div className="LandingMainImage">
              <Image id="home-banner" source="bannerImages" />
            </div>
          </div>
          <div className="LandingRightContainer">
            <HomepageMarketing>
              <div className="content-main user-top kale">
                <div>
                  <Typography variant="h1" color="secondary.light" fontWeight="900">
                    ORDER NOW
                  </Typography>
                  <DiningChoiceSelector />
                </div>
              </div>
            </HomepageMarketing>
            {isDeliveryOrder && <Addresses isSelectable />}
            {isLoyaltyEnabled && <LoyaltyRewardsList size="Medium" showHeader />}
            <SelectedReward />
            {userOrders.length > 0 && (
              <div className="user-order">
                <SectionSeparator typographyVariant="h4">YOUR ACTIVE ORDERS</SectionSeparator>
                <InfiniteScroll
                  dataLength={userOrders.length}
                  next={onLoadMoreOngoingOrders}
                  hasMore={hasMoreOngoingOrders}
                  loader={<InfiniteScrollLoader />}
                  scrollableTarget="LandingWrapper"
                >
                  {userOrders.map((order) => (
                    <CurrentOrder key={order.id} order={order} />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {isLoyaltyEnabled && <LoyaltyFab />}
      <LoyaltyTabs type="tab" />
    </>
  );
}

Landing.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  hasMoreOngoingOrders: PropTypes.bool.isRequired,
  userOrders: PropTypes.arrayOf(userOrderShape),
  isLoyaltyEnabled: PropTypes.bool.isRequired,
  onLoadMoreOngoingOrders: PropTypes.func.isRequired,
};

Landing.defaultProps = {
  userOrders: null,
};

export default Landing;
