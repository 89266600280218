import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';

import { getCollectionSteps } from '../../../utils/loyaltyUtils';

import rewardShape from '../../../shapes/rewardShape';

import ProgressBar from '../../ProgressBar/ProgressBar';

import './LoyaltyCard.css';

function PromoCard({ promo }) {
  const isSubscribed = promo.isCollection && promo.earned > 0;
  const remaining = (promo?.total ?? 0) - (promo?.earned ?? 0);
  const steps = getCollectionSteps(promo);
  const hasSecondary = steps.length > 0;

  const remainingLabel = (() => {
    if (promo && remaining) {
      if (hasSecondary) {
        const nextStep = steps.sort().find((value) => value > promo.earned);

        if (nextStep) {
          return `${remaining} more to the next reward!`;
        }
      }
      return `${remaining} more to go!`;
    }
    return '';
  })();

  return (
    <div className={classnames('LoyaltyCard', isSubscribed ? 'Subscribed' : 'NotSubscribed')}>
      <div className="TitleContainer">
        <div className="Title">
          <Typography className="TitleLabel" variant="h5">
            {promo.title}
          </Typography>
        </div>
      </div>
      <div className="LoyaltyCardBody">
        <div className="LoyaltyCardContentContainer">
          <div className="NameContainer">
            <Typography variant="subtitle2" color="grey.main">
              {promo.desc}
            </Typography>
          </div>
          <div className="CardBottom">
            {promo.isCollection && <ProgressBar value={(promo.earned / promo.total) * 100} />}
            <div className="ValidityRow">
              {promo.rewardEnd && (
                <Typography variant="body2" className="Validity" color="grey.main">
                  Valid until{' '}
                  <span className="bold">{moment(promo.rewardEnd).format('D MMM YYYY')}</span>
                </Typography>
              )}
              {promo.isCollection && remaining && (
                <Typography variant="body2" className="Remaining" color="grey.main">
                  {remainingLabel}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PromoCard.propTypes = {
  promo: rewardShape.isRequired,
};

export default PromoCard;
