import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import paymentMethodShape from '../../../shapes/paymentMethodShape';

import AddItemButton from '../../AddItemButton';
import ErrorSticker from '../../ErrorSticker';
import FeedbackSticker from '../../FeedbackSticker';
import PaymentMethod from '../PaymentMethod';

import { ReactComponent as CheckIcon } from '../../../images/icon-check-orange.svg';

import './PaymentMethods.css';

function PaymentMethods({
  paymentMethods,
  notification,
  error,
  defaultPaymentMethod,
  onPaymentMethodRemoveClick,
  onPaymentMethodAddClick,
  onSetDefaultClick,
}) {
  return (
    <div className="PaymentMethods">
      <ul className="title-function past-order">
        <li>
          <Typography variant="h3" color="secondary.light" className="AccountTitle">
            Saved cards
          </Typography>
        </li>
      </ul>
      {notification && (
        <FeedbackSticker className="editPaymentMethodSticker">
          <div className="NotificationBox">
            <CheckIcon />
            <p>{notification}</p>
          </div>
        </FeedbackSticker>
      )}
      {error && <ErrorSticker className="edit-account-p">{error}</ErrorSticker>}
      <ul className="cc-list">
        {defaultPaymentMethod && (
          <PaymentMethod
            key={defaultPaymentMethod.id}
            brand={defaultPaymentMethod.cardBrand}
            label={defaultPaymentMethod.label}
            isDefault
            onPaymentMethodRemoveClick={() => onPaymentMethodRemoveClick(defaultPaymentMethod.id)}
            onSetDefaultClick={() => onSetDefaultClick(defaultPaymentMethod.id)}
          />
        )}
        {paymentMethods
          .filter((paymentMethod) => paymentMethod.id !== defaultPaymentMethod?.id)
          .map(
            (paymentMethod) =>
              paymentMethod && (
                <PaymentMethod
                  key={paymentMethod.id}
                  brand={paymentMethod.cardBrand}
                  label={paymentMethod.label}
                  isDefault={false}
                  onPaymentMethodRemoveClick={() => onPaymentMethodRemoveClick(paymentMethod.id)}
                  onSetDefaultClick={() => onSetDefaultClick(paymentMethod.id)}
                />
              ),
          )}
        <AddItemButton text="Add payment method" onClick={onPaymentMethodAddClick} />
      </ul>
    </div>
  );
}

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(paymentMethodShape).isRequired,
  notification: PropTypes.string,
  error: PropTypes.string,
  defaultPaymentMethod: PropTypes.string.isRequired,
  onPaymentMethodRemoveClick: PropTypes.func.isRequired,
  onPaymentMethodAddClick: PropTypes.func.isRequired,
  onSetDefaultClick: PropTypes.func.isRequired,
};

PaymentMethods.defaultProps = {
  notification: null,
  error: null,
};

export default PaymentMethods;
