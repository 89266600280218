import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { getIsStationFlow } from '../../sagas/selectors';
import { setSearchParams } from '../../utils/historyUtils';

import GglocationsList from '../../containers/GglocationLocator/GglocationsList';
import Map from '../../containers/Map';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import Footer from '../Landing/Footer';

import './GglocationLocator.css';

function GglocationLocator(props) {
  const { menuItemId, isStoreSelected, locationSearch } = props;

  const isStationFlow = useSelector(getIsStationFlow);
  const { width } = useWindowSize();
  const isMobile = width < 767;

  return (
    <>
      <div className="GglocationLocator">
        <Map />
        <div className="StoreContainer">
          <div className="content-main kale">
            <Typography variant={isMobile ? 'h1' : 'h1'} color="secondary.light" fontWeight="900">
              SELECT {isStationFlow ? 'STATION' : 'STORE'}
            </Typography>
          </div>
          <Typography variant="subtitle1" color="grey.main" className="Disclaimer">
            {isStationFlow
              ? 'Order by the stipulated timings to get your food delivered to a Station near you'
              : 'Please expect longer wait times between 12 - 1:30pm'}
          </Typography>
          <GglocationsList menuItemId={menuItemId} />
          <div className="ActionButton">
            <Button
              className="Split btn-next"
              disabled={!isStoreSelected}
              link={{ pathname: '/time', search: setSearchParams(locationSearch, { menuItemId }) }}
            >
              <span className="Text">{isStoreSelected ? 'Next' : 'Next'}</span>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

GglocationLocator.propTypes = {
  menuItemId: PropTypes.string,
  isStoreSelected: PropTypes.bool,
  locationSearch: PropTypes.string,
};

GglocationLocator.defaultProps = {
  menuItemId: null,
  isStoreSelected: false,
  locationSearch: undefined,
};

export default GglocationLocator;
