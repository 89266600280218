import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import cyoSectionShape from '../../../shapes/cyoSectionShape';
import ingredientCategoryShape from '../../../shapes/ingredientCategoryShape';

import CategoryPaginationBar from '../../../containers/CategoryPaginationBar';
import DietTags from '../../DietTags';

import './MenuDetailsAddHeader.css';

function MenuDetailsAddHeader(props) {
  const { type, section, ingredientCategoriesList, ingredientServingsCount } = props;

  return (
    <div className="MenuDetailsAddHeader kale">
      <div className="MenuDetailsAddTitle">
        <Typography variant="h2" color="secondary.dark">
          {section.name} {ingredientServingsCount != null && `(${ingredientServingsCount})`}
        </Typography>
        <ul className="CategoryChoice">
          <CategoryPaginationBar
            categoryIds={ingredientCategoriesList.map(
              (ingredientCategory) => ingredientCategory.id,
            )}
          />
        </ul>
      </div>
      {type !== 'cyo' && (
        <div className="MenuDetailsAddDietTagsRow">
          <DietTags />
        </div>
      )}
    </div>
  );
}

MenuDetailsAddHeader.propTypes = {
  type: PropTypes.oneOf(['cyo', 'premade']),
  section: cyoSectionShape.isRequired,
  ingredientCategoriesList: PropTypes.arrayOf(ingredientCategoryShape).isRequired,
  ingredientServingsCount: PropTypes.number,
};

MenuDetailsAddHeader.defaultProps = {
  type: 'premade',
  ingredientServingsCount: null,
};

export default MenuDetailsAddHeader;
