import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Buffer } from 'buffer';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as orderActions from '../../../actions/order';
import { brandNameEnum } from '../../../enums/brandEnum';
import * as selectors from '../../../sagas/selectors';

import { CLIENT_BRAND } from '../../../version';
import Button from '../../Button';
import Modal from '../../Modal';

import { paymentService } from '../../../services/api';
import { APPLEPAY_DOMAIN_NAME, COUNTRY_CODE, CURRENCY_CODE } from '../../../settings';

import './ApplePayButton.css';

function ApplePayButton({ disabled }) {
  /* if you want to run apple pay button locally, please add 'export HTTPS=true&&PORT=3000'
     to the 'yarn start' in package.json as apple pay can only run on secured page */
  const price = useSelector(selectors.getOrderTotalPrice);
  const selectedPaymentMethod = useSelector(selectors.getSelectedPaymentMethod);

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onApplePayClick = () => {
    if (disabled) {
      return;
    }

    const paymentRequest = {
      countryCode: COUNTRY_CODE,
      currencyCode: CURRENCY_CODE,
      total: {
        label: 'Total',
        amount: price,
      },
      supportedNetworks: ['masterCard', 'visa'],
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
    };

    const applePaySession = new window.ApplePaySession(3, paymentRequest);

    applePaySession.onvalidatemerchant = async (event) => {
      const validationUrl = event.validationURL;
      const domainName = APPLEPAY_DOMAIN_NAME; // must be same domain as website
      const displayName = brandNameEnum[CLIENT_BRAND.toLowerCase()];
      const { response, error } = await paymentService.validateApplePay.call({
        validationUrl,
        domainName,
        displayName,
      });
      if (error) {
        setShowModal(true);
        applePaySession.abort();
      } else {
        try {
          applePaySession.completeMerchantValidation(response);
        } catch (e) {
          // to catch error when user cancel the apple pay
          console.log(e);
        }
      }
    };

    applePaySession.onpaymentauthorized = (event) => {
      const paymentMethodToken = Buffer.from(
        JSON.stringify(event.payment.token.paymentData),
      ).toString('base64');
      const paymentMethod = { ...selectedPaymentMethod, paymentMethodToken };

      dispatch(orderActions.selectPaymentMethod(paymentMethod));
      dispatch(orderActions.placeOrder());
      dispatch(orderActions.handlePlaceOrderApplePay(applePaySession));
    };

    applePaySession.oncancel = (event) => {
      // Payment canceled by WebKit
      console.log(event);
    };

    applePaySession.begin();
  };

  return !showModal ? (
    <Button
      className={classnames('apple-pay-button apple-pay-button-black', { grey: disabled })}
      onClick={onApplePayClick}
    />
  ) : (
    <Modal className="modal-small" show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="kale">
        <Typography variant="h3" color="secondary.dark">
          Oops! We&apos;re sorry...
        </Typography>
        <p>
          Your transaction could not be processed. Please place order using a different payment
          method.
        </p>
        <Button className="btn-primary" onClick={handleCloseModal}>
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}

ApplePayButton.propTypes = {
  disabled: PropTypes.bool,
};

ApplePayButton.defaultProps = {
  disabled: false,
};

export default ApplePayButton;
