import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../Button';

import './ErrorBoundary.css';

function ErrorBoundary({ reloadApp, reportToSentry }) {
  return (
    <div className="App">
      <div className="ErrorBoundary">
        <div className="ErrorBoundaryContent kale">
          <Typography variant="h3" color="secondary.dark" className="ErrorBoundaryTitle">
            Oops, something&apos;s gone wrong ☹️
          </Typography>
          <p>We&apos;re on it, but you can also add additional details in a report.</p>
          <div className="Buttons">
            <Button onClick={reportToSentry} className="btn-secondary">
              Report
            </Button>
            <Button className="btn-primary" onClick={reloadApp}>
              Reload
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorBoundary.propTypes = {
  reloadApp: PropTypes.func.isRequired,
  reportToSentry: PropTypes.func.isRequired,
};

export default ErrorBoundary;
