import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as selectors from '../../sagas/selectors';

import Button from '../Button';
import FontAwesome from '../FontAwesome';
import { ReactComponent as FacebookLogo } from '../Landing/Footer/icon-fb.svg';
import { ReactComponent as InstaLogo } from '../Landing/Footer/icon-insta.svg';

import * as settings from '../../settings';
import { CONTACT_EMAIL, FACEBOOK_LINK, INSTAGRAM_LINK, OFFICIAL_SITE_LINK } from '../../settings';

import './MenuMobile.css';

function MenuMobile(props) {
  const {
    loggedIn,
    onHomeClick,
    onSignInClick,
    onSignOutClick,
    onMobileMenuClose,
    isDeliveryAvailable,
  } = props;

  const firstName = useSelector(selectors.getFirstName);
  const giftCardUrl = useSelector(selectors.getGiftCardUrl);
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  return (
    <div>
      <div className="MenuMobile">
        <Button className="MenuMobileCloseButton" onClick={onMobileMenuClose}>
          <FontAwesome name="xmark" />
        </Button>
        <ul>
          {loggedIn ? (
            <>
              <li>
                <Typography variant="h4" color="secondary.light" className="MenuMobileName">
                  {firstName}
                </Typography>
              </li>
              <li>
                <Button className="blue-link" onClick={onSignOutClick}>
                  Log out
                </Button>
              </li>
            </>
          ) : (
            <li>
              <Button onClick={onSignInClick}>
                <Typography variant="h4" color="secondary.light">
                  Log in
                </Typography>
              </Button>
            </li>
          )}
        </ul>
        <ul className="menu-mobile-links">
          <li className="section-label">
            <Button
              className={classNames('MenuMobileHomeButton', { isHomePage })}
              onClick={onHomeClick}
            >
              Home
            </Button>
          </li>
          {loggedIn && (
            <>
              <li>
                <NavLink to="/account/edit">Account details</NavLink>
              </li>
              <li>
                <NavLink to="/account/paymentMethods">Saved cards</NavLink>
              </li>
              {isDeliveryAvailable && (
                <li>
                  <NavLink to="/account/addresses">Addresses</NavLink>
                </li>
              )}
              <li>
                <NavLink to="/account/orders">Order history</NavLink>
              </li>
              <li>
                <NavLink to="/account/favorites">Favourites</NavLink>
              </li>
            </>
          )}
        </ul>
        <ul className="menu-last-chunk">
          <li className="section-label">
            <Button href={OFFICIAL_SITE_LINK} rel="noopener noreferrer" target="_blank">
              Our Website
            </Button>
          </li>
          <li>
            <Button href={`/privacy-policy/${settings.COUNTRY_CODE.toLowerCase()}/`}>
              Privacy Policy
            </Button>
          </li>
          <li>
            <Button href="/terms-and-conditions/">Terms &amp; Conditions</Button>
          </li>
          <li>
            <Button href={`mailto:${CONTACT_EMAIL}`}>Contact Us</Button>
          </li>
          {giftCardUrl && (
            <li>
              <Button href={giftCardUrl} rel="noopener noreferrer" target="_blank">
                Gift Cards
              </Button>
            </li>
          )}
          <li className="MenuMobileLogoRow">
            <a href={FACEBOOK_LINK} rel="noopener noreferrer" target="_blank" aria-label="facebook">
              <FacebookLogo />
            </a>
            <a
              href={INSTAGRAM_LINK}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="instagram"
            >
              <InstaLogo />
            </a>
          </li>
        </ul>
      </div>
      <div role="presentation" className="MenuOverlay" onClick={onMobileMenuClose} />
    </div>
  );
}

MenuMobile.propTypes = {
  loggedIn: PropTypes.bool,
  isDeliveryAvailable: PropTypes.bool.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onSignInClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
  onMobileMenuClose: PropTypes.func.isRequired,
};

MenuMobile.defaultProps = {
  loggedIn: false,
};

export default MenuMobile;
