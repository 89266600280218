import React from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import 'antd/lib/date-picker/style/css';

import { getUserUuid } from '../../../sagas/selectors';

import ErrorSticker from '../../ErrorSticker';
import FeedbackSticker from '../../FeedbackSticker';
import FontAwesome from '../../FontAwesome';
import EditAccountForm from '../EditAccountForm';
import EditPartnerEmailSnackbar from '../EditPartnerEmailSnackbar';

import './Edit.css';

function Edit(props) {
  const { notification, error } = props;
  const userUUID = useSelector(getUserUuid);

  return (
    <div>
      {userUUID && (
        <div className="QrCodeSection">
          <div className="QrCodeImage">
            <QRCode size={256} bgColor="#fffaf1" value={userUUID} viewBox="0 0 256 256" />
          </div>
        </div>
      )}
      <ul className="title-function">
        <li>
          <Typography variant="h3" color="secondary.light" className="AccountTitle">
            Account
          </Typography>
        </li>
      </ul>
      {notification && (
        <FeedbackSticker className="edit-account-p">
          <div>
            <FontAwesome name="circle-check" type="regular" /> {notification}
          </div>
        </FeedbackSticker>
      )}
      <EditPartnerEmailSnackbar />
      {error && <ErrorSticker className="edit-account-p">{error}</ErrorSticker>}
      <EditAccountForm />
      {error && <ErrorSticker className="edit-account-p">{error}</ErrorSticker>}
    </div>
  );
}

Edit.propTypes = {
  notification: PropTypes.string,
  error: PropTypes.string,
};

Edit.defaultProps = {
  notification: null,
  error: null,
};

export default Edit;
