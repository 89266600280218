import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

import preferenceGroupShape from '../../../shapes/preferenceGroupShape';

import './PersonalizePreferenceGroupSwitch.css';

function PersonalizePreferenceGroupSwitch(props) {
  const { preferenceGroup, selectedPreferenceId, onPreferenceChange } = props;
  const isRecommended = preferenceGroup.recommended;
  const recommendedtext = <span className="recommended-text">(Recommended)</span>;

  return (
    <ButtonToolbar className="PersonalizePreferenceGroupSwitch">
      <ToggleButtonGroup
        type="radio"
        name={uuidV4}
        value={selectedPreferenceId}
        onChange={onPreferenceChange}
      >
        {preferenceGroup.preferences.map((preference) => (
          <ToggleButton key={preference.id} value={preference.id}>
            <Typography variant="subtitle1" fontWeight="600">
              {preference.name}
              {isRecommended && recommendedtext}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
}

PersonalizePreferenceGroupSwitch.propTypes = {
  preferenceGroup: preferenceGroupShape.isRequired,
  selectedPreferenceId: PropTypes.number.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
};

export default PersonalizePreferenceGroupSwitch;
