import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import classnames from 'classnames';

import * as selectors from '../../sagas/selectors';
import { isFetchNeeded } from '../../utils/apiUtils';
import {
  getEverydayClaims,
  getSpecialClaims,
  getSpecialRewards,
  getValidClaims,
  getValidPromos,
  getValidRewards,
} from '../../utils/loyaltyUtils';

import Button from '../Button';
import Footer from '../Landing/Footer';
import LoyaltyTabs from '../LoyaltyTabs';
import {
  ClaimCard,
  LoyaltyCard,
  MassRewardCard,
  PromoCard,
  SpecialRewardCard,
} from './LoyaltyCard';
import RewardModal from './RewardModal';

import { loyaltyService } from '../../services/api';

import './LoyaltyCollection.css';

function LoyaltyCollection() {
  const rewards = useSelector(selectors.getCollections);
  const promos = useSelector(selectors.getPromos);
  const claims = useSelector(selectors.getClaims);
  const specials = useSelector(selectors.getSpecials);
  const brand = useSelector(selectors.getLoyaltyBrandPrefix);
  const { initData: initDataStatus } = useSelector(selectors.getLoyaltyStatus);
  const landingPageSlug = useSelector(selectors.getCurrentLandingPageSlug);
  const validRewards = getValidRewards({ rewards });
  const validPromos = getValidPromos({ promos });
  const [showNonSpecialRewards, setShowNonSpecialRewards] = useState(true);
  const validSpecialRewards = getSpecialRewards({ rewards: specials });
  const validClaims = getValidClaims({ claims });
  const everydayClaims = getEverydayClaims({ claims: validClaims });
  const specialClaims = getSpecialClaims({ claims: validClaims });

  const noRewardEarned = validRewards.length + validPromos.length + validClaims.length < 0;
  const massRewards = useSelector(selectors.getMassRewards);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetching loyalty init-data only when needed
    // as it is not force-fetched when rehydrate to protect loyalty backend performance
    if (isFetchNeeded(initDataStatus)) {
      dispatch(loyaltyService.initData.requestActionCreator({ brand }));
    }
  }, [dispatch, initDataStatus]);

  const onOrderNow = () => {
    navigate(landingPageSlug ? `/site/${landingPageSlug}` : '/');
  };

  return (
    <>
      <RewardModal />
      <div className="LoyaltyWrapper">
        <div className="LoyaltyCollection">
          <div className="tabsRow">
            <Button
              className={classnames('Button tabsButton', { selected: showNonSpecialRewards })}
              onClick={() => setShowNonSpecialRewards(true)}
            >
              <Typography variant="h6" fontWeight="1000" lineHeight="normal">
                Everyday Rewards
              </Typography>
            </Button>
            <Button
              className={classnames('Button tabsButton', { selected: !showNonSpecialRewards })}
              onClick={() => setShowNonSpecialRewards(false)}
            >
              <Typography variant="h6" fontWeight="1000" lineHeight="normal">
                Special Rewards
              </Typography>
            </Button>
          </div>
          <div className="SectionDescription">
            {noRewardEarned ? (
              <>
                <Typography variant="subtitle2" color="grey.main">
                  You have not earned any rewards yet.
                </Typography>
                <Typography variant="subtitle2" color="grey.main">
                  Scan in-store receipts to earn chops towards exciting rewards!
                  <br />
                  Online orders will be credited automatically.
                </Typography>
                <Button className="btn-primary" onClick={onOrderNow}>
                  Proceed to order
                </Button>
              </>
            ) : (
              <>
                <Typography variant="subtitle2" color="grey.main">
                  Scan in-store receipts to earn credits. Online orders will be credited
                  automatically.
                </Typography>
                <Button className="blue-link" link="/account/orders/?tab=rewards">
                  Go to rewards history
                </Button>
              </>
            )}
          </div>
          {showNonSpecialRewards ? (
            <div className="LoyaltyCards">
              {everydayClaims.map((claim) => (
                <ClaimCard key={claim.id} claim={claim} />
              ))}

              {validRewards.map((reward) => (
                <LoyaltyCard key={reward.id} reward={reward} />
              ))}
              {validPromos.length > 0 && (
                <div className="PromoSection">
                  <Typography variant="h4" color="primary" className="SectionTitle">
                    Promotions
                  </Typography>
                  {validPromos.map((promo) => (
                    <PromoCard key={promo.id} promo={promo} />
                  ))}
                </div>
              )}

              {massRewards.length > 0 && (
                <div className="MassRewardSection">
                  {massRewards.map((massReward) => (
                    <MassRewardCard key={massReward.id} massReward={massReward} />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="LoyaltyCards">
              {specialClaims.map((claim) => (
                <ClaimCard key={claim.id} claim={claim} />
              ))}
              {validSpecialRewards.map((reward) => (
                <SpecialRewardCard key={reward.id} reward={reward} />
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
      <LoyaltyTabs type="tab" />
    </>
  );
}

export default LoyaltyCollection;
