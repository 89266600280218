import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as orderItemActions from '../../actions/orderItem';
import * as selectors from '../../sagas/selectors';

import CarbonFootprint from '../CarbonFootprint';
import Modal from '../Modal';
// eslint-disable-next-line import/no-cycle
import NutritionalSection from '../NutritionalSection';

import './NutritionalModal.css';

function NutritionalModal({ name, show, isMobile, onHide }) {
  const dispatch = useDispatch();
  const { nutritionalGroups } = useSelector(selectors.getApiNutritionalSettings);
  const activeGroupId = useSelector(selectors.getActiveNutritionalGroup);

  const onGroupSelect = (nutritionalGroupId) => {
    dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId }));
  };

  useEffect(() => {
    /* When the modal is shown in mobile mode, nutritional group logic applies */
    if (!activeGroupId && show) {
      const nutritionalGroupId = Object.values(nutritionalGroups)[0]?.id;
      dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId }));
    }
    /* When the modal is not open, we use nutritional information (no groups) in the little tab above */
    if (!show && isMobile) {
      dispatch(orderItemActions.setActiveNutritionalGroup({ nutritionalGroupId: null }));
    }
  }, [show]);

  return (
    <Modal className="NutritionalModal" show={show} onHide={onHide}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body className="kale">
        <div className="content-top">
          <Typography variant="h3" color="secondary.dark">
            {name}
          </Typography>
        </div>
        <CarbonFootprint />
        <Tabs
          defaultActiveKey={Object.values(nutritionalGroups)[0].id}
          id="NutritionalTabs"
          onSelect={onGroupSelect}
        >
          {Object.values(nutritionalGroups).map((nutritionalGroup) => (
            <Tab
              key={nutritionalGroup.id}
              eventKey={nutritionalGroup.id}
              title={nutritionalGroup.title}
            >
              <NutritionalSection sectionIds={nutritionalGroup.sectionIds} />
            </Tab>
          ))}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

NutritionalModal.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

NutritionalModal.defaultProps = {
  show: false,
};

export default NutritionalModal;
