import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { push } from 'redux-first-history';

import * as apiActions from '../../actions/api';
import * as geolocationAction from '../../actions/geolocation';
import * as loyaltyActions from '../../actions/loyalty';
import * as pageActions from '../../actions/page';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';
import { getUrlFromString } from '../../utils/commonUtils';
import { getSearchParam } from '../../utils/historyUtils';

import useTimeout from '../../hooks/useTimeout';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import ErrorSticker from '../ErrorSticker';
import FeedbackSticker from '../FeedbackSticker';
import FontAwesome from '../FontAwesome';
import Modal from '../Modal';

import barcodeIcon from '../../images/icon-barcode-red.svg';
import cameraIcon from '../../images/icon-camera-red.svg';
import { ReactComponent as HistoryIcon } from '../../images/icon-history.svg';
import localeIcon from '../../images/icon-locale-red.svg';
import { ReactComponent as MobileIcon } from '../../images/icon-mobile.svg';
import receiptIcon from '../../images/icon-receipt-red.svg';

import './BarcodeScanner.css';

const errorIconMapping = Object.freeze({
  camera: cameraIcon,
  locale: localeIcon,
  scan: barcodeIcon,
  receipt: receiptIcon,
});

function BarcodeScanner() {
  const dispatch = useDispatch();
  const isShown = useSelector(selectors.getIsScannerModalOpen);
  const { width } = useWindowSize();
  const isMobile = width < 767;

  const { scanCode } = useSelector(selectors.getApiStatus);
  const scanCodeError = useSelector((state) => selectors.getApiError(state, 'scanCode'));
  const geolocation = useSelector(selectors.getGeolocation);
  const [isErrorEnabled, setIsErrorEnabled] = useState(false);
  const [isScannerError, setIsScannerError] = useState(false);
  const [errorIcon, setErrorIcon] = useState('scan');
  const [errorTitle, setErrorTitle] = useState(null);
  const [error, setError] = useState(null);

  const isLoading = [statusEnum.AWAITING, statusEnum.REQUESTED].includes(scanCode);
  const isSuccessful = scanCode === statusEnum.SUCCESS;

  const startErrorTimer = useTimeout(() => {
    setIsErrorEnabled(true);
  });
  const stopErrorTimer = useTimeout(() => {
    setIsErrorEnabled(false);
  });
  const closeModalTimer = useTimeout(() => {
    dispatch(pageActions.closeScannerModal());
  });

  const handleDismiss = () => {
    dispatch(pageActions.closeScannerModal());
  };

  const handleGotoHistory = () => {
    dispatch(pageActions.closeScannerModal());
    dispatch(push('/account/orders?tab=rewards'));
  };

  const handleScanData = (result) => {
    if (result) {
      setError(null);
      setIsErrorEnabled(false);
      startErrorTimer(5000);

      // Send code only on the first time, if it has not yet succeeded
      // in this session to prevent double scanning
      //
      // After every successful scanning, this modal/user will need to
      // close and reopen for a new scan to reset the api status
      // unless we have new specs for continuous scanning
      if (!isSuccessful) {
        // Attempt to extract url from the code (for kiosk pairing, scan)
        const url = getUrlFromString(result);
        if (url) {
          if (url.pathname.match('kioskPairing')) {
            dispatch(push({ pathname: url.pathname, search: url.search }));
          } else if (url.pathname.match('scan')) {
            const code = getSearchParam(url.search, 'code');
            dispatch(loyaltyActions.dispatchScanCode({ newCode: code, geolocation }));
          } else {
            setError('URL is invalid');
          }
        } else if (result.text) {
          dispatch(loyaltyActions.dispatchScanCode({ newCode: result.text, geolocation }));
        }
      }
    } else if (isScannerError) {
      // Remove scanner error when start streaming
      setError(null);
      setIsScannerError(false);
    }
  };

  useEffect(() => {
    /* If the scan succeeds, show the success message for a bit then close the modal */
    if (isSuccessful) {
      closeModalTimer(3000);
    }
  }, [isSuccessful, closeModalTimer]);

  useEffect(() => {
    /* Give some time initially before starting to show errors */
    if (isShown) {
      dispatch(geolocationAction.requestGeolocation());
      stopErrorTimer(5000);
    }
  }, [isShown, dispatch, stopErrorTimer]);

  useEffect(() => {
    if (!isScannerError) {
      /* Set or clear api error */
      setError(scanCodeError?.error?.message);
      setErrorTitle('Error processing receipt');
      if (scanCodeError?.error?.message.includes('Invalid receipt scanned')) {
        setErrorIcon('scan');
      } else {
        setErrorIcon('receipt');
      }
    }
  }, [scanCodeError, isScannerError]);

  const onScannerError = (scannerError) => {
    let errMsg = '';
    // Refer the following for the potential exception raised
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
    switch (scannerError.name) {
      case 'NotAllowedError':
        errMsg = (
          <>
            <p>Please allow access to your camera in both website and browser permission</p>
            <br />
            <p>Both these permissions need to be enabled for scanning to work.</p>
          </>
        );
        break;
      case 'NotFoundError':
        errMsg =
          'We are unable to find a camera on your current device, ' +
          'thus cannot continue to scan a receipt.';
        break;
      default:
        errMsg =
          'We are unable to access to your camera, please review ' +
          `the following for more detail: '${scannerError.message}`;
    }
    if (errMsg) {
      setError(errMsg);
      setErrorTitle('Unable to access camera');
      setErrorIcon('camera');
      setIsScannerError(true);
    }
  };

  const onScanErrorRetry = () => {
    setError(null);
    setIsScannerError(false);
    dispatch(apiActions.clearApiEndpointErrors('scanCode'));
  };

  useEffect(() => {
    setError(null);
  }, [isShown]);

  return (
    <Modal show={isShown} className="ScannerModal">
      <Modal.Body>
        {isMobile ? (
          <>
            <div className="Topbar">
              <Button fa="xmark" className="TopbarButton" onClick={handleDismiss} />
              <div className="Title">
                <Typography variant="h5">Scan in-store receipts</Typography>
              </div>
              <Button className="TopbarButton ScanHistoryButton" onClick={handleGotoHistory}>
                <HistoryIcon />
              </Button>
            </div>
            <div className="ScanInstructions">
              <Typography variant="subtitle1" fontWeight="600">
                Scan the QR code on your receipt You must enable both location and camera access to
                scan.
              </Typography>
            </div>
            {isSuccessful && (
              <FeedbackSticker>
                Barcode scanning successful!
                <br />
                It may take up to 24h to be credited to your account.
              </FeedbackSticker>
            )}
            {error ? (
              <ErrorSticker icon={errorIconMapping[errorIcon]} title={errorTitle} noIcon>
                {error}
                <Button className="btn-primary BarcodeRetryButton" onClick={onScanErrorRetry}>
                  Retry
                </Button>
              </ErrorSticker>
            ) : (
              <div className="BarcodeScannerContainer">
                {isLoading ? (
                  <FontAwesome name="circle-o-notch" spin />
                ) : (
                  <>
                    <BarcodeScannerComponent
                      className="BarcodeScannerComponent"
                      onUpdate={(err, result) => {
                        if (err && isErrorEnabled) {
                          if (!err.message.includes('MultiFormat')) {
                            setError(err.message);
                          }
                        } else {
                          handleScanData(result);
                        }
                      }}
                      onError={onScannerError}
                    />
                    <div className="Rectangle" />
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <div>
            <MobileIcon />
            <Typography variant="h5" color="primary">
              Visit Heybo on mobile
            </Typography>
            <Typography variant="subtitle2" className="DesktopNote">
              Receipt scanning is only available on mobile devices.
            </Typography>
            <Button className="btn-primary" onClick={handleDismiss}>
              Okay
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default BarcodeScanner;
