import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import coordinatesShape from '../../shapes/coordinatesShape';

import AddressAutocomplete from '../AddressAutocomplete';
import AddressMap from '../AddressMap';
import Button from '../Button';
import Footer from '../Landing/Footer';

import 'leaflet/dist/leaflet.css';
import './AddAddress.css';

function AddAddress({
  error,
  address,
  addressCoordinates,
  postcode,
  floorUnit,
  buildingName,
  coordinates,
  deliveryInstruction,
  isHidePostcode,
  isPostcodeEditable,
  isInputFocused,
  onAddressSelect,
  onPostcodeChange,
  onFloorUnitChange,
  onBuildingNameChange,
  onMapCoordinatesChange,
  onDeliveryInstructionChange,
  onInputFocus,
  onInputBlur,
  onFormSubmit,
}) {
  return (
    <>
      <div className={classnames('AddAddress', { isInputFocused })}>
        <AddressMap
          address={address}
          addressCoordinates={addressCoordinates}
          coordinates={coordinates}
          zoom={address ? 19 : 13}
          onCoordinatesChange={onMapCoordinatesChange}
        />
        <div className="ContentContainer">
          <form className="FormContainer" onSubmit={onFormSubmit}>
            <div className="FormContent">
              <Typography
                variant="h1"
                color="secondary.light"
                className="title"
                align="center"
                fontWeight="900"
              >
                DELIVER TO
              </Typography>
              <ul>
                <li>
                  <AddressAutocomplete
                    address={address}
                    hasError={error && error.field === 'address'}
                    onAddressSelect={onAddressSelect}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                  />
                </li>
                {error && error.field === 'address' && (
                  <li className="InputAlertText center">
                    <p>{error.message}</p>
                  </li>
                )}
                {error && error.field === 'address' && (
                  <li className="InputAlertText center">
                    <p>{error.message}</p>
                  </li>
                )}
              </ul>
              {address && (
                <>
                  {!isHidePostcode && (
                    <ul>
                      <li>
                        <Typography className="Label" variant="h5" color="secondary.light">
                          Postcode
                        </Typography>
                      </li>
                      <li>
                        <input
                          className={classnames({ hasError: error && error.field === 'postcode' })}
                          type="text"
                          value={postcode}
                          readOnly={!isPostcodeEditable}
                          onChange={onPostcodeChange}
                        />
                      </li>
                      {error && error.field === 'postcode' && (
                        <li className="InputAlertText">
                          <p>{error.message}</p>
                        </li>
                      )}
                    </ul>
                  )}
                  <ul>
                    <li>
                      <Typography className="Label" variant="h5" color="secondary.light">
                        Apartment, suite, unit, etc.
                      </Typography>
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Optional"
                        value={floorUnit}
                        onChange={onFloorUnitChange}
                      />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Typography className="Label" variant="h5" color="secondary.light">
                        Building name
                      </Typography>
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Optional"
                        value={buildingName}
                        onChange={onBuildingNameChange}
                      />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Typography className="Label" variant="h5" color="secondary.light">
                        Delivery instructions
                      </Typography>
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Optional"
                        value={deliveryInstruction}
                        onChange={onDeliveryInstructionChange}
                      />
                    </li>
                  </ul>
                </>
              )}
              {error && !error.field && (
                <div className="input-alert-text center">
                  <p>
                    <span className="lnr lnr-warning" /> {error.message}
                  </p>
                </div>
              )}
            </div>
            <div className="ActionButton">
              <Button className="btn-next" disabled={!address} onClick={onFormSubmit}>
                <span className="Text">Next</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

AddAddress.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    field: PropTypes.string,
  }),
  address: PropTypes.string.isRequired,
  addressCoordinates: coordinatesShape.isRequired,
  postcode: PropTypes.string.isRequired,
  floorUnit: PropTypes.string.isRequired,
  buildingName: PropTypes.string.isRequired,
  coordinates: coordinatesShape.isRequired,
  deliveryInstruction: PropTypes.string,
  isHidePostcode: PropTypes.bool.isRequired,
  isPostcodeEditable: PropTypes.bool.isRequired,
  isInputFocused: PropTypes.bool.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  onPostcodeChange: PropTypes.func.isRequired,
  onFloorUnitChange: PropTypes.func.isRequired,
  onBuildingNameChange: PropTypes.func.isRequired,
  onMapCoordinatesChange: PropTypes.func.isRequired,
  onDeliveryInstructionChange: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

AddAddress.defaultProps = {
  error: null,
  deliveryInstruction: null,
};

export default AddAddress;
