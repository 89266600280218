import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import * as pageActions from '../../../actions/page';
import * as selectors from '../../../sagas/selectors';
import { getCollectionSteps } from '../../../utils/loyaltyUtils';

import Modal from '../../Modal';
import MomentCalendar from '../../MomentCalendar';
import ProgressBar from '../../ProgressBar/ProgressBar';
import BonusSticker from '../BonusSticker';
import TNCSection from '../TNCSection';

import './RewardModal.css';

function RewardModal() {
  const dispatch = useDispatch();
  const reward = useSelector(selectors.getSelectedReward);
  const isShown = useSelector(selectors.getIsRewardModalOpen) && reward;

  const handleCloseModal = () => {
    dispatch(pageActions.closeRewardModal());
  };
  /* Make sure the modal is closed when unmounting */
  useEffect(
    () => () => {
      dispatch(pageActions.closeRewardModal());
    },
    [],
  );

  const remaining = (reward?.total ?? 0) - (reward?.earned ?? 0);
  const steps = getCollectionSteps(reward);
  const hasSecondary = steps.length > 0;

  const remainingLabel = (() => {
    if (reward && remaining) {
      if (hasSecondary) {
        const nextStep = steps.sort().find((value) => value > reward.earned);

        if (nextStep) {
          return `${remaining} more to the next reward!`;
        }
      }
      return `${remaining} more to go!`;
    }
    return '';
  })();

  return (
    <Modal className="RewardModal" show={isShown} onHide={handleCloseModal}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body>
        {reward && (
          <div className="Content">
            <div className="Header">
              <Typography variant="h4" color="secondary.dark">
                {reward.title}
              </Typography>
            </div>
            <div className="TextContainer">
              <Typography variant="subtitle1" className="RewardName">
                {reward.name}
              </Typography>
              {!reward.isCompleted && (reward.isCollection || reward.isSpecial) && (
                <ProgressBar value={(reward.earned / reward.total) * 100} steps={steps} />
              )}
              <div className="ValidityRow">
                {reward.rewardEnd && (
                  <Typography variant="body2" className="Validity" color="grey.main">
                    Valid until:{' '}
                    <span className="bold">
                      <MomentCalendar>{reward.rewardEnd}</MomentCalendar>
                    </span>
                  </Typography>
                )}
                {remaining && (
                  <Typography variant="body2" className="Remaining" color="grey.main">
                    {remainingLabel}
                  </Typography>
                )}
              </div>
            </div>
            <div className="TextContainer Description">
              {hasSecondary && (
                <>
                  {Object.entries(reward.secondary).map(([value, name]) => (
                    <Typography key={`${value}-${name}`} variant="subtitle2">
                      <strong>{value} chops: </strong>
                      {name}
                    </Typography>
                  ))}
                  <Typography variant="subtitle2">
                    <strong>{Number(reward.total)} chops: </strong>
                    {reward.rewardName}
                  </Typography>
                </>
              )}
              {!hasSecondary && <Typography variant="subtitle2">{reward.desc}</Typography>}
            </div>
            {reward.bonus && (
              <TNCSection
                title={<BonusSticker bonus={reward.bonus} />}
                text={reward?.bonus?.desc}
                collapse
              />
            )}
            <TNCSection text={reward.tnc} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default RewardModal;
