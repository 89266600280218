import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import validator from 'email-validator';

import * as userActions from '../../actions/user';
import * as selectors from '../../sagas/selectors';

import Button from '../Button';
import EmailAddress from '../EmailAddress';
import InputAlertText from '../InputAlertText';
import Modal from '../Modal';

import { auth2Service, oauthService } from '../../services/api';

import './FacebookReset.css';

const accountStatusEnums = Object.freeze({
  ACCOUNT_NOT_EXIST: 'ACCOUNT_NOT_EXIST',
  NORMAL_ACCOUNT_EXISTS: 'NORMAL_ACCOUNT_EXISTS',
  FACEBOOK_ACCOUNT_EXISTS: 'FACEBOOK_ACCOUNT_EXISTS',
  ERROR: 'ERROR',
});

function FacebookReset() {
  const dispatch = useDispatch();
  const [accountStatus, setAccountStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(0);
  const isPasswordModalShown = useSelector(selectors.getIsPasswordModalShown);

  const validateEmail = () => validator.validate(email);

  const handleEmailChange = (event) => setEmail(event.target.value.toLowerCase());

  const handleForgetPassword = () => {
    dispatch(auth2Service.forgetPassword.requestActionCreator({ email }));
    setTimer(60);
  };

  const handlePasswordModalHide = () => {
    dispatch(userActions.hidePasswordModal());
  };

  const getAccountStatus = (response) => {
    if (response.exists) {
      if (response.isSocialLogin) {
        handleForgetPassword();
        return accountStatusEnums.FACEBOOK_ACCOUNT_EXISTS;
      }
      return accountStatusEnums.NORMAL_ACCOUNT_EXISTS;
    }
    return accountStatusEnums.ACCOUNT_NOT_EXIST;
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const body = {
      email,
    };
    const isValid = validateEmail();
    setIsEmailValid(isValid);
    if (isValid) {
      try {
        const result = await oauthService.checkUserExists.call({ body });
        const { response, error } = result;

        if (response && response.success) {
          setAccountStatus(getAccountStatus(response));
        } else if (error) {
          setAccountStatus(accountStatusEnums.ERROR);
          setErrorMessage(`Error: ${error.message}`);
        } else {
          setAccountStatus(accountStatusEnums.ERROR);
          setErrorMessage(`Error: Unexpected response from server`);
        }
      } catch (error) {
        setAccountStatus(accountStatusEnums.ERROR);
        setErrorMessage(`Error: ${error.message}`);
      }
    }
  };

  const getAccountStatusComponent = () => {
    switch (accountStatus) {
      case accountStatusEnums.ACCOUNT_NOT_EXIST:
        return (
          <div>
            Please double check your Facebook email or sign up for a new account{' '}
            <Link className="linkText" to="/signIn/">
              here
            </Link>{' '}
            instead.
          </div>
        );
      case accountStatusEnums.NORMAL_ACCOUNT_EXISTS:
        return (
          <div>
            This account is already configured for email login, please reset your password and login
            with your email.
          </div>
        );
      case accountStatusEnums.FACEBOOK_ACCOUNT_EXISTS:
        return (
          <div>
            We have sent a password reset link to the email above. Did not receive it? Check your
            spam or{' '}
            {timer === 0 ? (
              <Button onClick={() => handleForgetPassword()}>
                <span className="linkText">resend</span>
              </Button>
            ) : (
              <span className="linkText">resend in {timer}s</span>
            )}
          </div>
        );
      case accountStatusEnums.ERROR:
        return <div>Error when processing request. Please try again later.</div>;
      default:
        return (
          <div>
            If you’ve never logged in via Facebook, please sign up for a new account{' '}
            <Link className="linkText" to="/signIn/">
              here
            </Link>{' '}
            instead.
          </div>
        );
    }
  };

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    const getAccountStatusAlert = () => {
      if (isEmailValid === false) {
        setErrorMessage('Invalid email address.');
        return;
      }
      switch (accountStatus) {
        case accountStatusEnums.ACCOUNT_NOT_EXIST:
          setErrorMessage('Email not found.');
          break;
        case accountStatusEnums.NORMAL_ACCOUNT_EXISTS:
          setErrorMessage('This account uses normal login method.');
          break;
        // Skips setting error message for error case,
        // as it is set immediately after api call
        case accountStatusEnums.ERROR:
          break;
        default:
          setErrorMessage(null);
      }
    };
    getAccountStatusAlert();
  }, [accountStatus, isEmailValid]);

  return (
    <form onSubmit={handleEmailSubmit}>
      <Typography variant="h1" color="secondary.light" className="SignInTitle">
        Oops!
      </Typography>
      <Typography variant="h1" color="secondary.light" className="SignInTitle">
        We&apos;re sorry...
      </Typography>
      <p className="middle-text">
        We no longer support Facebook login. Please enter your Facebook email and we will send you a
        link to set a new password.
      </p>
      <div>
        <Typography variant="subtitle1" color="secondary.dark" className="EmailLabel">
          Email
        </Typography>
        <EmailAddress
          className={classnames(
            { 'input-alert': errorMessage },
            'EmailAddressLight',
            'FacebookResetPage',
          )}
          type="text"
          placeholder="hello@saladstop.sg"
          value={email}
          onChange={handleEmailChange}
        />
        <InputAlertText noIcon>{errorMessage}</InputAlertText>
        {timer === 0 ? (
          <Button className="btn-primary" disabled={email?.length < 1}>
            Send password reset link
          </Button>
        ) : (
          <Button className="btn-primary" disabled>
            Email sent
          </Button>
        )}
        <div className="middle-text">{getAccountStatusComponent()}</div>
      </div>
      <Modal className="modal-small" show={isPasswordModalShown} onHide={handlePasswordModalHide}>
        <Modal.Body className="kale">
          <Typography variant="h3" color="secondary.dark">
            Check your email
          </Typography>
          <p>
            Please follow the instruction we sent to &nbsp;
            <strong>{email}</strong>
            &nbsp; to reset your password.
          </p>
          <Button className="btn-primary" onClick={handlePasswordModalHide}>
            Okay
          </Button>
        </Modal.Body>
      </Modal>
    </form>
  );
}

export default FacebookReset;
