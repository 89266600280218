import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { getSearchParam } from '../../../utils/historyUtils';

import headerShape from '../../../shapes/headerShape';

import logoWhite from './logo-header.svg';
import logoSmallWhite from './logo-small-white.svg';

function TopBarTitle(props) {
  const { header, isLandingPageOpen, isOverlay } = props;

  const [searchParams] = useSearchParams();

  const isCartShown = getSearchParam(searchParams, 'showCart') === 'true';

  const defaultTitleElement = (
    <div className="logo-header">
      <img src={logoWhite} alt="Heybo" />
    </div>
  );

  const desktopTitleElement =
    header.desktop.title !== '' ? (
      <ul className="TopBarInfo">
        <li>
          <Typography variant="h4" className="TopBarSubtitle">
            {header.desktop.subtitle}
          </Typography>
        </li>
        {!isLandingPageOpen && (
          <li>{header.desktop.icon || <img src={logoSmallWhite} alt="Heybo" />}</li>
        )}
        <li>
          <Typography variant="h4">{header.desktop.title}</Typography>
        </li>
      </ul>
    ) : (
      defaultTitleElement
    );

  const mobileTitleElement =
    header.mobile.title !== '' ? (
      <Typography variant="h4">
        {header.mobile.icon}
        {header.mobile.title}
      </Typography>
    ) : (
      defaultTitleElement
    );

  return (
    <div className="TopBarTitle">
      <div className="visible-lg visible-md">
        {isOverlay ? defaultTitleElement : desktopTitleElement}
      </div>
      <div className="visible-xs visible-sm TopBarMobileTitle">
        {isCartShown ? <Typography variant="h4">Checkout</Typography> : mobileTitleElement}
      </div>
    </div>
  );
}

TopBarTitle.propTypes = {
  isOverlay: PropTypes.bool,
  header: headerShape.isRequired,
  isLandingPageOpen: PropTypes.bool.isRequired,
};

TopBarTitle.defaultProps = {
  isOverlay: false,
};

export default TopBarTitle;
