import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classNames from 'classnames';

import * as loyaltyActions from '../../actions/loyalty';
import * as pageActions from '../../actions/page';
import sceneEnum from '../../enums/sceneEnum';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';

import useTimeout from '../../hooks/useTimeout';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import TNCSection from '../LoyaltyCollection/TNCSection';
import Modal from '../Modal';
import MomentCalendar from '../MomentCalendar';
import SlideButton from '../SlideButton';

import { loyaltyService } from '../../services/api';
import * as settings from '../../settings';

import './HighlightedRewardModal.css';

const modalScenes = Object.freeze({
  INITIAL: 'initial',
  REDEEM_IN_STORE: 'redeemInStore',
  SHOW_REDEEM_CODE: 'showRedeemCode',
  SHOW_PROMPT_SCAN: 'showPromptScan',
  CODE_REDEEMED: 'codeRedeemed',
});

function HighlightedRewardModal() {
  const dispatch = useDispatch();
  const [modalScene, setModalScene] = useState(modalScenes.INITIAL);
  const [flying, setFlying] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 767;

  const reward = useSelector(selectors.getHighlightedReward);
  const { claimReward } = useSelector(selectors.getLoyaltyStatus);
  /* TODO: Add error handling for this case (design not available yet) */
  // const claimRewardErrors = useSelector(
  //   (state) => selectors.getLoyaltyError(state, 'claimReward')
  // );

  const flyingTimeout = useTimeout(() => {
    dispatch(loyaltyActions.confirmHighlightedUserReward());
    setFlying(false);
  });

  const showPromptScan = settings.LOYALTY_REDEEM_IN_STORE_PROMPT_SCAN ?? false;

  const onHide = () => {
    /* If the code is shown, claim the reward */
    if (modalScene === modalScenes.SHOW_REDEEM_CODE) {
      dispatch(
        loyaltyService.claimReward.requestActionCreator({
          rewardType: reward.rewardType,
          rewardId: reward.id,
        }),
      );
    }
    dispatch(loyaltyActions.unsetHighlightedUserReward());
    setModalScene(modalScenes.INITIAL);
  };

  const onRedeemOnline = () => {
    setFlying(true);
    flyingTimeout(480);
  };

  const onRedeemStore = () => {
    if (showPromptScan) {
      setModalScene(modalScenes.SHOW_PROMPT_SCAN);
    } else {
      setModalScene(modalScenes.REDEEM_IN_STORE);
    }
  };

  const onRedeemConfirmed = () => {
    dispatch(
      loyaltyService.claimReward.requestActionCreator({
        rewardType: reward.rewardType,
        rewardId: reward.id,
      }),
    );
    setModalScene(modalScenes.CODE_REDEEMED);
  };

  const onScanKiosk = () => {
    onHide();
    dispatch(pageActions.setPageScene(sceneEnum.SCAN_RECEIPT));
  };

  const renderScene = () => {
    switch (modalScene) {
      case modalScenes.INITIAL:
        return (
          <div className="RedeemSection HighlightedRewardModalSection">
            {reward.claimCondition && (
              <Typography variant="subtitle2" className="RewardDescription">
                {reward.claimCondition}
              </Typography>
            )}
            <Button className="btn-primary" onClick={onRedeemOnline}>
              Redeem online
            </Button>
            {isMobile && (
              <Button className="btn-secondary" onClick={onRedeemStore}>
                Redeem in Store
              </Button>
            )}
            {reward.validUntil && (
              <Typography variant="body2" className="Validity" color="grey.main">
                Valid until:{' '}
                <span className="bold">
                  <MomentCalendar>{reward.validUntil}</MomentCalendar>
                </span>
              </Typography>
            )}
          </div>
        );
      case modalScenes.REDEEM_IN_STORE:
        return (
          <div className="RedeemSection HighlightedRewardModalSection">
            <Typography variant="h5" color="primary">
              Redeem in store
            </Typography>
            <Typography variant="subtitle2" color="grey.main">
              Show this screen to the team at Heybo
            </Typography>
            <SlideButton onSuccess={() => setModalScene(modalScenes.SHOW_REDEEM_CODE)} />
            <Button className="blue-link" onClick={() => setModalScene(modalScenes.INITIAL)}>
              {' '}
              Cancel{' '}
            </Button>
          </div>
        );
      case modalScenes.SHOW_PROMPT_SCAN:
        return (
          <div className="RedeemSection HighlightedRewardModalSection">
            <div className="PromptScanSection">
              <ol>
                <li>
                  <Typography variant="subtitle2">Go to a Heybo kiosk</Typography>
                </li>
                <li>
                  <Typography variant="subtitle2">Select &lt;Redeem reward&gt;</Typography>
                </li>
                <li>
                  <Typography variant="subtitle2">
                    Use &lt;Scan&gt; to scan the displayed QR code
                  </Typography>
                </li>
              </ol>
            </div>
            <Button className="btn-primary" onClick={onScanKiosk}>
              Scan now
            </Button>
            <Button className="btn-secondary" onClick={() => setModalScene(modalScenes.INITIAL)}>
              Back to reward
            </Button>
          </div>
        );
      case modalScenes.SHOW_REDEEM_CODE:
        return (
          <div className="RedeemSection HighlightedRewardModalSection">
            <Typography variant="h5" color="primary">
              Redeem in store
            </Typography>
            <Typography variant="h1" className="ClaimNumber">
              {reward.code}
            </Typography>
            <Button className="btn-primary" onClick={onRedeemConfirmed}>
              {' '}
              Okay{' '}
            </Button>
          </div>
        );
      case modalScenes.CODE_REDEEMED:
        return (
          <div className="RedeemSection HighlightedRewardModalSection">
            {claimReward === statusEnum.SUCCESS && (
              <Typography variant="h3" className="ClaimNumber">
                Reward Redeemed
              </Typography>
            )}
            {claimReward === statusEnum.ERROR && (
              <Typography variant="h5">An error occured when claiming the reward</Typography>
            )}
            <Button className="btn-primary" onClick={onHide}>
              {' '}
              Okay{' '}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  if (!reward) return null;

  const showPromptScanTitle = showPromptScan && modalScene === modalScenes.SHOW_PROMPT_SCAN;

  return (
    <Modal className={classNames('HighlightedRewardModal', { flying })} show onHide={onHide}>
      <div className="modal-control">
        <ul>
          <li>
            <Modal.Dismiss className="btn-close-modal" />
          </li>
        </ul>
      </div>
      <Modal.Body className="kale">
        <div className="RewardInfo">
          <div className="HighlightedRewardModalSection">
            <Typography variant="h4" className="RewardTitle" color="secondary.dark">
              {showPromptScanTitle ? 'Redeem in-store' : reward.claimName}
            </Typography>
          </div>
        </div>
        {renderScene()}
        <TNCSection text={reward.claimCondition} />
      </Modal.Body>
    </Modal>
  );
}

export default HighlightedRewardModal;
