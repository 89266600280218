import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import userOrderItemShape from '../../../shapes/userOrderItemShape';

import OrderItem from '../../../containers/Order/OrderItem';
import Button from '../../Button';
import Modal from '../../Modal';

import './NameFavorite.css';

function NameFavorite(props) {
  const { favoriteOrderItem, name, onNameChange, onHide, onSave } = props;
  return (
    <Modal className="NameFavorite" show={favoriteOrderItem !== null} onHide={onHide}>
      <Modal.Body className="kale">
        <Typography variant="h3" color="secondary.dark">
          Add to favourites
        </Typography>
        <ul className="kale">
          {favoriteOrderItem && (
            <OrderItem size="Small" orderItem={favoriteOrderItem} isButtonHidden />
          )}
        </ul>
        <input
          className="OrderInfo modal-input"
          type="text"
          placeholder="Name your favourite"
          onChange={onNameChange}
          value={name}
        />
        <div className="NameFavoriteButtonRow">
          <Button className="btn-secondary" onClick={onHide}>
            Back
          </Button>
          <Button className="btn-primary" onClick={onSave}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NameFavorite.propTypes = {
  favoriteOrderItem: userOrderItemShape.isRequired,
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NameFavorite;
