import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ingredientCategoryShape from '../../shapes/ingredientCategoryShape';

import IngredientsPicker from '../../containers/IngredientsPicker';
import DietTags from '../DietTags';

import './IngredientCategories.css';

function IngredientCategories(props) {
  const {
    menuItemId,
    sectionId,
    ingredientCategoriesList,
    type,
    excludeNullBase,
    saveAutomatically,
    showDietTags,
    getCategoryIngredientServingsCount,
    onIngredientCategoryEnter,
    onIngredientCategoryLeave,
    onLimitExhausted,
    onLimitReached,
  } = props;

  return (
    <div className="IngredientCategories">
      {ingredientCategoriesList.map((ingredientCategory, index) => {
        const ingredientsCount = getCategoryIngredientServingsCount(ingredientCategory.id);
        return (
          <div id={`IngredientCategory-${ingredientCategory.id}`} key={ingredientCategory.id}>
            <Waypoint
              onEnter={(waypoint) => onIngredientCategoryEnter(ingredientCategory.id, waypoint)}
              onLeave={(waypoint) => onIngredientCategoryLeave(ingredientCategory.id, waypoint)}
            >
              <div>
                <div className="IngredientCategoryTitle kale">
                  <Typography
                    variant={ingredientCategoriesList.length > 1 ? 'h5' : 'h2'}
                    color={ingredientCategoriesList.length > 1 ? 'secondary.light' : 'secondary.dark'}
                    fontWeight="400"
                  >
                    {ingredientCategory.name}{' '}
                    {ingredientCategoriesList.length < 2 && `(${ingredientsCount ?? '0'})`}
                  </Typography>
                  {showDietTags && ingredientCategoriesList.length < 2 && index === 0 && (
                    <DietTags />
                  )}
                </div>
                <IngredientsPicker
                  key={ingredientCategory.id}
                  menuItemId={menuItemId}
                  sectionId={sectionId}
                  categoryIds={[ingredientCategory.id]}
                  type={type}
                  excludeNullBase={excludeNullBase}
                  saveAutomatically={saveAutomatically}
                  onLimitExhausted={onLimitExhausted}
                  onLimitReached={onLimitReached}
                />
              </div>
            </Waypoint>
          </div>
        );
      })}
    </div>
  );
}

IngredientCategories.propTypes = {
  menuItemId: PropTypes.number.isRequired,
  sectionId: PropTypes.arrayOf(PropTypes.number).isRequired,
  ingredientCategoriesList: PropTypes.arrayOf(ingredientCategoryShape).isRequired,
  type: PropTypes.oneOf(['cyo', 'premade']),
  excludeNullBase: PropTypes.bool,
  saveAutomatically: PropTypes.bool,
  showDietTags: PropTypes.bool,
  getCategoryIngredientServingsCount: PropTypes.func.isRequired,
  onIngredientCategoryEnter: PropTypes.func.isRequired,
  onIngredientCategoryLeave: PropTypes.func.isRequired,
  onLimitExhausted: PropTypes.func.isRequired,
  onLimitReached: PropTypes.func.isRequired,
};

IngredientCategories.defaultProps = {
  type: 'premade',
  excludeNullBase: false,
  saveAutomatically: false,
  showDietTags: true,
};

export default IngredientCategories;
